import axios from "axios";

import { put, takeLatest } from "redux-saga/effects";
import { baseUrl, config } from "../../appSettings";
import { db, clearAllStores } from "../../indexeddb/indexeddb";

// worker Saga: will be fired on "FETCH_USER" actions
function* fetchUser(action) {
  try {
    const dbAccount = yield db.account.get({ email: action.payload.email });
    // the config includes credentials which
    // allow the server session to recognize the user
    // If a user is logged in, this will return their information
    // from the server session (req.user)
    if (dbAccount != null && dbAccount.email === action.payload.email) {
      console.log("account should be the same as log in email", dbAccount);
      yield put({ type: "SET_LOADING_WITH_PROGRESS", payload: "Loading App Data" });

      yield put({ type: "SET_USER", payload: dbAccount });
      yield put({ type: "SYNC_USER", payload: dbAccount.id });
      const response = yield axios.get(`${baseUrl}/User/UserDataByFilter?email=${action.payload.email}`, config);
      yield db.account.put(response.data[0]);
    } else {
      console.log("account is null ", dbAccount);
      const response = yield axios.get(`${baseUrl}/User/UserDataByFilter?email=${action.payload.email}`, config);
      yield db.account.clear();
      console.log("userResponse", response.data[0]);
      yield db.account.add(response.data[0]);
      yield clearAllStores();
      yield put({ type: "SET_USER", payload: response.data[0] });
      yield put({
        type: "FULL_PULL_BY_USER",
        payload: response.data[0].id,
      });
    }
    // now that the session has given us a user object
    // with an id and username set the client-side user object to let
    // the client-side code know the user is logged in
  } catch (error) {
    console.log("User get request failed", error);
  }
}

function* userSaga() {
  yield takeLatest("FETCH_USER", fetchUser);
}

export default userSaga;
