import axios from "axios";
import dayjs from "dayjs";
import { db } from "../indexeddb/indexeddb";
import { baseUrl, config } from "../appSettings";
import { store } from "../index";
import dateTimeModule from "../Modules/dateTime";

const getAllByUser = (id) => {
  return axios.get(baseUrl + `/Company/CompanyMasterDataByFilter?UserId=${id}`, config);
};

const sync = async (lastSync, id) => {
  //get all Companies from db
  let allFromDb = await db.companies.toArray();
  //get current company from db

  console.log("Companies from sync", allFromDb);
  let newFromDb = allFromDb.filter((a) => a.isNew === true);
  let updatedFromDb = allFromDb.filter((a) => a.isUpdate === true && a.isNew === false);
  let deletedFromDb = allFromDb.filter((a) => a.isDelete === true);

  for (let record of newFromDb) {
    insertNewFromSync(record);
  }

  for (let record of updatedFromDb) {
    update(record);
  }

  for (let record of deletedFromDb) {
    deleteFromSync(record);
  }
  let newFromServer = await getNewFromServer(lastSync, id);
  for (let data of newFromServer.data) {
    let found = await db.companies.get(data.id);
    console.log(`found company:`, found);
    if (found) {
      db.companies.put(data);
    } else {
      db.companies.add(data);
    }
  }
  let updatedFromServer = await getUpdatedFromServer(lastSync, id);
  await db.companies.bulkPut(updatedFromServer.data);
  let deletedFromServer = await getDeletedFromServer(lastSync, id);
  for (let deleted of deletedFromServer.data) {
    let found = await db.companies.get(deleted.id);
    if (found) {
      db.companies.delete(deleted.id);
    }
  }
  console.log(`deleted Companies from server:`, deletedFromServer);
  console.log("new Companies from server", newFromServer);
  console.log("updated Companies from server", updatedFromServer);
  return await db.companies.toArray();
};

const insertNewFromSync = async (data) => {
  try {
    data.createdByDate = dateTimeModule.currentUtc();
    await axios.post(`${baseUrl}/Company`, data, config);
    data.isNew = false;
    db.companies.put(data);
  } catch (err) {}
};

const insert = async (data) => {
  try {
    data.createdByDate = dateTimeModule.currentUtc();
    data.isNew = true;
    await db.companies.add(data);
    await axios.post(`${baseUrl}/Company`, data, config);
    data.isNew = false;
    db.companies.put(data);
    return data;
  } catch (err) {
    return data;
  }
};

const update = async (data) => {
  console.log("updateCompany");
  try {
    data.lastUpdatedByDate = dateTimeModule.currentUtc();
    data.isUpdate = true;
    console.log(data);
    db.companies.put(data);
    await axios.put(`${baseUrl}/Company/${data.id}`, data, config);
    console.log("after axios call");
    data.isUpdate = false;
    db.companies.put(data);
    return data;
  } catch (err) {
    return data;
  }
};

const deleteFromSync = async (data) => {
  try {
    data.inActiveDate = dateTimeModule.currentUtc();
    await axios.put(`${baseUrl}/Company/${data.id}`, data, config);
    db.companies.delete(data.id);
  } catch (err) {}
};

const deleteFromServer = async (data) => {
  try {
    data.inActiveDate = dateTimeModule.currentUtc();
    data.isDelete = true;
    await db.companies.put(data);
    await axios.put(`${baseUrl}/Company/${data.id}`, data, config);
    db.companies.delete(data.id);
  } catch (err) {}
};

const getNewFromServer = async (lastUpdated, user) => {
  return axios.get(
    `${baseUrl}/Company/CompanyMasterDataByFilter?UserId=${user}&LessThenCreatedByDate=${encodeURIComponent(
      lastUpdated
    )}`,
    config
  );
};

const getUpdatedFromServer = async (lastUpdated, user) => {
  return axios.get(
    `${baseUrl}/Company/CompanyMasterDataByFilter?UserId=${user}&LessThenUpdatedByDate=${encodeURIComponent(
      lastUpdated
    )}`,
    config
  );
};

const getDeletedFromServer = async (lastUpdated, user) => {
  return axios.get(
    `${baseUrl}/Company/CompanyMasterDataByFilter?UserId=${user}&LessThenDeletedByDate=${encodeURIComponent(
      lastUpdated
    )}`,
    config
  );
};

const updateFromSignalR = async (data) => {
  let found = await db.companies.get(data.id);
  console.log(`found companies:`, found);
  if (data.inActiveDate != null) {
    if (found) {
      db.companies.delete(data.id);
      store.dispatch({ type: "DELETE_COMPANY_REDUCER", payload: [data.id] });
    }
  } else if (found) {
    db.companies.put(data);
    store.dispatch({ type: "UPDATE_COMPANY_REDUCER", payload: data });
  } else {
    db.companies.add(data);
    store.dispatch({ type: "INSERT_COMPANY_REDUCER", payload: data });
  }
};

const companyService = {
  getAllByUser: getAllByUser,
  sync: sync,
  update: update,
  insert: insert,
  deleteFromServer: deleteFromServer,
  updateFromSignalR: updateFromSignalR,
};

export default companyService;
