import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

function displayTimeStamp(time) {
  let displayString = dayjs.utc(time).local().format("MM-DD-YYYY hh:mmA");
  return displayString === "Invalid Date" ? "" : displayString;
}

function displayTimeStampForChartJS(time) {
  let displayString = dayjs.utc(time).local().format("YYYY-MM-DDTHH:mm:ss.sssZ");
  return displayString === "Invalid Date" ? "" : displayString;
}

function displayDate(time) {
  let displayString = dayjs.utc(time).local().format("MM-DD-YYYY");
  return displayString === "Invalid Date" ? "" : displayString;
}
function dateTimePickerFormat(time) {
  let formatted = dayjs.utc(time).local().format("YYYY-MM-DDTHH:mm");
  // console.log("dateTimePickerFormat:", formatted);
  return formatted;
}

function datePickerFormat(time) {
  return dayjs.utc(time).format("YYYY-MM-DD");
}

function saveDate(time) {
  let saveTime = dayjs.utc(time).format("YYYY-MM-DDTHH:mm:ssZ");
  return saveTime;
}

function saveTimeStamp(time) {
  // console.log("time into save time:", time);
  let saveTime = dayjs(time).utc().format("YYYY-MM-DDTHH:mm:ssZ");
  // console.log("saveTime:", saveTime);
  return saveTime;
}

function currentUtc() {
  return dayjs().utc().format("YYYY-MM-DDTHH:mm:ssZ");
}
function currentUtcMinus(days) {
  return dayjs().utc().subtract(days, "days").format("YYYY-MM-DDTHH:mm:ssZ");
}
function currentUtcPlus(days) {
  return dayjs().utc().add(days, "days").format("YYYY-MM-DDTHH:mm:ssZ");
}

function getEstimatedInseminationDate(time, days) {
  return dayjs(time).utc().subtract(days, "days").format("YYYY-MM-DDTHH:mm:ssZ");
}

const dateTimeModule = {
  displayTimeStamp,
  displayTimeStampForChartJS,
  saveDate,
  currentUtc,
  dayjs,
  datePickerFormat,
  dateTimePickerFormat,
  saveTimeStamp,
  displayDate,
  currentUtcMinus,
  currentUtcPlus,
  getEstimatedInseminationDate,
};
window.dateTimeModule = dateTimeModule;

export default dateTimeModule;
