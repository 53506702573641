const poisReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_POIS": {
      let sorted = [...action.payload].sort((a, b) =>
        a.description > b.description ? 1 : b.description > a.description ? -1 : 0
      );
      window.poilist = sorted;
      return sorted;
    }
    case "INSERT_POI_REDUCER": {
      return [...state, action.payload];
    }
    case "UPDATE_POI_REDUCER": {
      let updatedState = state.map((item) => (item.id === action.payload.id ? action.payload : item));
      return updatedState;
    }
    case "DELETE_POI_REDUCER": {
      let items = action.payload;
      let newState = [...state];
      return newState.filter((item) => items.indexOf(item.id) === -1);
    }
    default:
      return state;
  }
};

export default poisReducer;
