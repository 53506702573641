import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LeftMapButtons from "./LeftMapButtons";
import BottomDock from "./BottomDock";
import ConfirmButtons from "./ConfirmButtons";
import SizeField from "./MapComponents/SizeField";
import SliderField from "./MapComponents/SliderField";
import FencePinButtons from "./MapComponents/FencePinButtons";
import PositionInfo from "./MapComponents/PositionInfo";
import MeasurementInfo from "./MapComponents/MeasurementInfo";
import ElevationTool from "./MapComponents/ElevationTool";
import MassSelectModal from "./MassSelectModal/MassSelectModal";
import MassCreateModal from "./MassCreateModal/MassCreateModal";
import { v4 as uuid } from "uuid";
import dateTimeModule from "../../Modules/dateTime";
import LocationTool from "./MapComponents/LocationTool";
import TrackingTool from "./MapComponents/TrackingTool";
import FenceHeatMap from "./MapComponents/FenceHeatMap";
import WeatherTool from "./MapComponents/WeatherTool";
import CellModal from "./CellModal/CellModal";
import animalService from "../../Services/animalService";
import fenceService from "../../Services/fenceService";
import fencePointService from "../../Services/fencePointService";
import poiService from "../../Services/poiService";

const Map = (props) => {
  const [map, setMap] = useState(props.mapState.map);
  const [showMassSelectModal, setShowMassSelectModal] = useState(false);
  const [showMassCreateModal, setShowMassCreateModal] = useState(false);
  const [showBluetoothModal, setShowBluetoothModal] = useState(false);
  const [showCellModal, setShowCellModal] = useState(false);
  const [timeStamp, setTimeStamp] = useState(null);

  window.setTimeStamp = setTimeStamp;
  // useEffect(() => console.log("timeStamp", timeStamp), [timeStamp]);

  const updateMapState = (value) => {
    props.dispatch({
      type: "UPDATE_MAP_STATE",
      payload: { ...props.mapState, map: value },
    });
    setMap(value);
    window.map = value;
  };
  const setMapReady = (value) => {
    props.dispatch({
      type: "UPDATE_MAP_STATE",
      payload: { ...props.mapState, mapReady: value },
    });
  };

  window.goToPage = (url) => {
    props.history.push(url);
  };

  window.updateMapState = (value) => {
    updateMapState(value);
  };

  window.setMapReady = (value) => {
    setMapReady(value);
  };
  window.setShowMassSelectModal = setShowMassSelectModal;
  window.setShowMassCreateModal = setShowMassCreateModal;
  window.setShowCellModal = setShowCellModal;

  function insertFromMap(action) {
    let company = props.companies.find((company) => company.id === props.defaults.company);
    let item = action.payload;
    item.id = uuid();
    item.companyId = company.id;
    item.createdByDate = dateTimeModule.currentUtc();
    item.createdByUserId = props.user.id;
    item.lastUpdatedDate = dateTimeModule.currentUtc();
    item.lastUpdatedByUserId = props.user.id;
    props.dispatch({ type: action.type, payload: item });
  }

  function updateFromMap(action) {
    let item = action.payload;
    item.lastUpdatedDate = dateTimeModule.currentUtc();
    item.lastUpdatedByUserId = props.user.id;
    props.dispatch({ type: action.type, payload: item });
  }

  function deleteFromMap(action) {
    let item = action.payload;
    props.dispatch({ type: action.type, payload: item });
  }
  window.insertFromMap = insertFromMap;
  window.updateFromMap = updateFromMap;
  window.deleteFromMap = deleteFromMap;

  useEffect(() => {
    let mapDiv = document.getElementById("myMap");
    mapDiv.classList.remove("hideMap");
    animalService.getGrandFarmAnimals().then((result) => props.dispatch({ type: "SET_ANIMALS", payload: result.data }));
    fenceService.getGrandFarm().then((result) => props.dispatch({ type: "SET_FENCES", payload: result.data }));
    fencePointService
      .getGrandFarm()
      .then((result) => props.dispatch({ type: "SET_FENCE_POINTS", payload: result.data }));
    poiService.getGrandFarm().then((result) => props.dispatch({ type: "SET_POIS", payload: result.data }));
    if (!map) {
      window.GetMap();
    } else {
      window.setUpSlider();
    }
    return () => {
      if (mapDiv.classList.contains("hideMap")) {
        return;
      } else {
        mapDiv.classList.add("hideMap");
      }
    };
  }, []);

  useEffect(() => {
    if (props.animals && props.mapState.mapReady) {
      window.animallist = props.animals;
      window.ReBuildAnimal();
    }
  }, [props.animals, props.mapState]);

  useEffect(() => {
    if (props.tags && props.mapState.mapReady) {
      window.taglist = props.tags;
      window.ReBuildTag();
    }
  }, [props.tags, props.mapState]);

  useEffect(() => {
    if (props.companies && props.mapState.mapReady) {
      window.companylist = props.companies;
      window.ReBuildCompany();
    }
  }, [props.companies, props.mapState]);

  useEffect(() => {
    if (props.ranches && props.mapState.mapReady) {
      window.ranchlist = props.ranches;
      window.ReBuildRanch();
    }
  }, [props.ranches, props.mapState]);

  useEffect(() => {
    if (props.pois && props.mapState.mapReady) {
      window.poilist = props.pois;
      window.ReBuildPOI();
    }
  }, [props.pois, props.mapState]);

  useEffect(() => {
    if (props.fences && props.fencePoints && props.mapState.mapReady) {
      window.geoFencePOIlist = props.fences;
      window.geoFencePointlist = props.fencePoints;
      window.ReBuildFence();
    }
  }, [props.fences, props.fencePoints, props.mapState]);
  function toggleBluetoothModal() {
    setShowBluetoothModal((prevState) => !prevState);
  }

  return (
    <>
      <SizeField />
      <SliderField />
      <PositionInfo />
      <MeasurementInfo />
      <LocationTool timeStamp={timeStamp} />
      <TrackingTool timeStamp={timeStamp} />
      <FenceHeatMap />
      <BottomDock timeStamp={timeStamp} />
      {/* <LeftMapButtons
        timeStamp={timeStamp}
        toggleBluetoothModal={toggleBluetoothModal}
        setShowCellModal={setShowCellModal}
      /> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  mapState: state.mapState,
  animals: state.animals,
  companies: state.companies,
  ranches: state.ranches,
  groups: state.groups,
  fences: state.fences,
  fencePoints: state.fencePoints,
  pois: state.pois,
  user: state.user,
  defaults: state.defaults,
  tags: state.tags,
});
export default connect(mapStateToProps)(withRouter(Map));
