import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";

const SelectorAnimalCard = (props) => {
  let { animal } = props;
  const [openInfo, setOpenInfo] = useState(false);

  function handleCheck(e) {
    if (e.target.checked) {
      if (props.numberOfAnimals === 1) {
        props.setSingleSelection(animal);
        props.setShowModal(false);
      } else {
        props.setSelected((prevState) => [...prevState, { ...animal }]);
      }
    } else {
      props.setSelected((prevState) => [...prevState.filter((a) => a.id !== animal.id)]);
    }
  }
  return (
    <div className="container-fluid listCard mt-3 mb-3 pl-2 pr-2" id="card">
      <div className="d-none d-xl-block row mb-0">
        <table className="table mb-0" style={{ tableLayout: "fixed" }}>
          <tbody>
            <tr>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                <input type="checkbox" checked={props.selected} onChange={handleCheck} />
                <br />
                <br />
                <FaInfoCircle onClick={() => setOpenInfo(!openInfo)} style={{ cursor: "pointer" }} />
              </td>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {animal.cattleNumber}
              </td>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {animal.networkNumber}
              </td>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {animal.code}
              </td>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {animal.description}
              </td>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {animal.type}
              </td>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {animal.breedDisplay}
              </td>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {animal.enteredFarm_Date}
              </td>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {animal.weight}
              </td>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {animal.hasCalved}
              </td>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {animal.companyDescription}
              </td>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {animal.farmDescription}
              </td>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {animal.groupDescription}
              </td>
              <td
                style={{
                  width: "7%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {animal.farmVirtualFenceDescription}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="d-flex d-xl-none row mb-0">
        <div className="col  text-center text-md-left">
          <h3 className="font-weight-bold mb-0"> {animal.cattleNumber} </h3>
          <span className="ml-2">{animal.code}</span>
          <br />
          <span className="ml-2">{animal.description}</span>
        </div>
      </div>
      <div className="row d-flex d-xl-none ">
        <div className="col-md-6  text-md-left order-0 order-md-1" style={{ overflow: "hidden" }}>
          <span>
            <span className="font-weight-bold ">Company:</span> {animal.companyDescription}
          </span>
          <br />
          <span>
            <span className="font-weight-bold">Ranch:</span> {animal.farmDescription}
          </span>
          <br />
          <span>
            <span className="font-weight-bold">Folder:</span> {animal.groupDescription}
          </span>{" "}
          <br />
          <div className="flex-nowrap">
            <span className="font-weight-bold">Fence:</span> {animal.farmVirtualFenceDescription}
          </div>
          <br />
        </div>
        <div className="col-md-6 text-md-left order-1 order-md-0">
          <span>
            <span className="font-weight-bold ">Type:</span> {animal.type}
          </span>
          <br />
          <span>
            <span className="font-weight-bold ">Date Of Birth:</span> {animal.dob}
          </span>
          <br />
          <span>
            <span className="font-weight-bold ">Weight:</span> {animal.weight}
          </span>
          <br />
          <span>
            <span className="font-weight-bold ">Has Calved:</span> {animal.hasCalved}
          </span>
          <br />
        </div>
      </div>
      <div className=" d-flex d-xl-none row">
        <div className="col-6">
          <label>Selected: </label>
          <input className="ml-1" type="checkbox" checked={props.selected} onChange={handleCheck} />
          <br />
          <FaInfoCircle onClick={() => setOpenInfo(!openInfo)} style={{ cursor: "pointer" }} />
        </div>
        <div className="col-6">
          <button
            className="btn btn-outline-dark btn-sm"
            style={{ float: "right" }}
            onClick={() => props.history.push(`/animal/${animal.id}`)}
          >
            Edit
          </button>
        </div>
      </div>
      <div className="d-flex container-fluid">
        <div
          className={openInfo ? "row hiddenInfoCard showmeInfoCard" : "row hiddenInfoCard"}
          style={{ width: "100%" }}
        >
          <div className="col-md-6 col-xl-4 mt-2">
            <span>
              <span className="font-weight-bold ">Cattle#:</span> {animal.cattleNumber}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Name:</span> {animal.code}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Description:</span> {animal.description}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Company:</span> {animal.companyDescription}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Ranch:</span> {animal.farmDescription}
            </span>
            <br />
          </div>
          <div className="col-md-6 col-xl-4 mt-2">
            <span>
              <span className="font-weight-bold ">Type:</span> {animal.type}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">701x Tag #:</span> {animal.networkNumber}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">USDA Tag #:</span> {animal.usdaNumber}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Folder:</span> {animal.groupDescription}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Fence:</span> {animal.farmVirtualFenceDescription}
            </span>
            <br />
          </div>
          <div className="col-md-6 col-xl-4 mt-2">
            <span>
              <span className="font-weight-bold ">Sire:</span> {animal.maleCattleNumber}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Dam:</span> {animal.femaleCattleNumber}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Foster:</span> {animal.fosterCattleNumber}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Breed:</span> {animal.breedDisplay}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Sex:</span> {animal.sex}
            </span>
            <br />
          </div>
          <div className="col-md-6 col-xl-4 mt-2">
            <span>
              <span className="font-weight-bold ">Age:</span> {animal.age}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">IsCalve:</span> {animal.isCalve}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Purchase Date:</span> {animal.purchase_Date}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Purchase Price:</span> {animal.purchase_Price}
            </span>
            <br />
          </div>

          <div className="col-md-6 col-xl-4 mt-2">
            <span>
              <span className="font-weight-bold ">Date of Birth:</span> {animal.dob}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Entered Farm Date:</span> {animal.enteredFarm_Date}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Cull Date:</span> {animal.cull_Date}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Death Date:</span> {animal.death_Date}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Sold Date:</span> {animal.sold_Date}
            </span>
            <br />
          </div>

          <div className="col-md-6 col-xl-4 mt-2">
            <span>
              <span className="font-weight-bold ">Sold Price:</span> {animal.sold_Price}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Event Status:</span> {animal.eventStatusId}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Event Type:</span> {animal.eventTypeId}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Health Scale:</span> {animal.healthScale}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Weight:</span> {animal.weight}
            </span>
            <br />
          </div>
          <div className="col-md-6 col-xl-4 mt-2">
            <span>
              <span className="font-weight-bold ">Do Not Breed:</span> {animal.doNotBreedFlag}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Freemartin:</span> {animal.isFreemartin}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Castrated:</span> {animal.isCrastrated}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Castration Method:</span> {animal.crastrationMethod}
            </span>
            <br />
          </div>
          <div className="col-md-6 col-xl-4 mt-2">
            <span>
              <span className="font-weight-bold ">Temp:</span> {animal.temperature}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Heading:</span> {animal.heading}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Longitude:</span> {animal.longitude}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Latitude:</span> {animal.latitude}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Alt:</span> {animal.altitude}
            </span>
            <br />
          </div>
          <div className="col-md-6 col-xl-4 mt-2">
            <span>
              <span className="font-weight-bold ">HasCalved:</span> {animal.hasCalved}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">HasAborted:</span> {animal.hasAborted}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">HasStillBorn:</span> {animal.hasStillBorn}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">IsOpen:</span> {animal.isOpen}
            </span>
            <br />
          </div>
          <div className="col-md-6 col-xl-4 mt-2">
            <span>
              <span className="font-weight-bold ">Created Date:</span> {animal.createdByDate}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">Updated Date:</span> {animal.lastUpdatedByDate}
            </span>
            <br />
            <span>
              <span className="font-weight-bold ">InActive Date:</span> {animal.inActiveDate}
            </span>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SelectorAnimalCard);
