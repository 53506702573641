import React, { useState } from "react";
import "../App/App.css";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { authMgr } from "../../Services/authMgrService";
import Logo from "../Shared/Svg/701x";
import { IoMdSync, IoMdMenu } from "react-icons/io";
import { FaCloudDownloadAlt } from "react-icons/fa";

function App(props) {
  const [open, setOpen] = useState(false);

  const logOut = () => {
    authMgr.signoutRedirect();
  };

  const closeNav = () => {
    let mapDiv = document.getElementById("myMap");
    mapDiv.classList.add("hideMap");
    setOpen(false);
  };
  const changeCompany = (event) => {
    props.dispatch({ type: "CHANGE_COMPANIES", payload: { company: event.target.value, user: props.user.id } });
  };
  const syncUser = () => {
    props.dispatch({ type: "SET_LOADING_WITH_PROGRESS", payload: "Syncing App Data" });
    props.dispatch({ type: "SYNC_USER", payload: props.user.id });
  };
  const fullPull = () => {
    props.dispatch({ type: "SET_LOADING_WITH_PROGRESS", payload: "Pulling App Data" });
    props.dispatch({ type: "CHANGE_COMPANIES", payload: { company: props.defaults.company, user: props.user.id } });
  };
  return (
    <>
      <button
        style={{ zIndex: "1000", position: "absolute", top: "50px", left: "10px", backgroundColor: "#eaeaea" }}
        className="btn  btn-outline-dark"
        onClick={() => setOpen(true)}
        onMouseEnter={() => setOpen(true)}
      >
        <IoMdMenu />
      </button>
      <button
        style={{ zIndex: "1000", position: "absolute", top: "50px", left: "55px", backgroundColor: "#eaeaea" }}
        className="btn  btn-outline-dark"
        onClick={syncUser}
      >
        <IoMdSync />
      </button>

      <div
        className={open ? "sidebar showSideNav" : "sidebar hideSideNav"}
        id="mySidenav"
        onMouseLeave={() => setOpen(false)}
      >
        <div className="top-row ps-4 pe-4 navbar navbar-dark">
          <Logo width={"100px"} />
          <button className="navbar-toggler " id="sidebarInnerButton" onClick={() => setOpen(false)}>
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>

        <div>
          <div className="text-center">
            <button className="btn btn-outline-light btn-sm m-2" onClick={fullPull}>
              <FaCloudDownloadAlt />
            </button>
            <select
              id="DefaultCompanyId"
              title="Company is required"
              value={props.defaults.company}
              onChange={changeCompany}
            >
              {props.companies.length > 0 &&
                props.companies?.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.description}
                  </option>
                ))}
            </select>
            <button className="btn btn-outline-light" onClick={logOut}>
              LogOut
            </button>
          </div>
          <ul className="nav flex-column">
            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/user/profile">
                <span className="oi oi-person" aria-hidden="true"></span> {props.user.email}
              </Link>
            </li>

            <li className="nav-item px-3" onClick={() => setOpen(false)}>
              <Link className="nav-link" to="/">
                <span className="oi oi-home" aria-hidden="true"></span> Home
              </Link>
            </li>

            <li className="nav-item px-3" onClick={() => setOpen(false)}>
              <Link className="nav-link" to="/map">
                <span className="oi oi-map"></span> Map
              </Link>
            </li>
            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/reports">
                <span className="oi oi-pie-chart" aria-hidden="true"></span> Reports
              </Link>
            </li>

            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/animal">
                <span className="oi oi-list-rich" aria-hidden="true"></span> Animal List
              </Link>
            </li>
            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/logs">
                <span className="oi oi-clipboard" aria-hidden="true"></span> Log List
              </Link>
            </li>
            {/* <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/comments">
                <span className="oi oi-comment-square" aria-hidden="true"></span> Note List
              </Link>
            </li>
            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/attachments/index">
                <span className="oi oi-folder" aria-hidden="true"></span> File List
              </Link>
            </li> */}

            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/tag">
                <span className="oi oi-tag" aria-hidden="true"></span> Tag List
              </Link>
            </li>
            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/groups">
                <span className="oi oi-list-rich" aria-hidden="true"></span> Folder List
              </Link>
            </li>
            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/pois">
                <span className="oi oi-flag" aria-hidden="true"></span> POI List
              </Link>
            </li>
            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/fence">
                <span className="oi oi-signpost" aria-hidden="true"></span> Fence List
              </Link>
            </li>
            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/ranch">
                <span className="oi oi-list-rich" aria-hidden="true"></span> Ranch List
              </Link>
            </li>
            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/company">
                <span className="oi oi-list-rich" aria-hidden="true"></span> Company List
              </Link>
            </li>
            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/companyUserDirectory">
                <span className="oi oi-people" aria-hidden="true"></span>User Directory
              </Link>
            </li>
            {props.user.roleId === "Administrator" && (
              <li className="nav-item px-3" onClick={closeNav}>
                <Link className="nav-link" to="/admin/users">
                  <span className="oi oi-chat" aria-hidden="true"></span> Admin User List
                </Link>
              </li>
            )}
            {/* @*<li className="nav-item px-3" onClick={closeNav}>
                <NavLink className="nav-link" to="tasks/index">
                    <span className="oi oi-check" aria-hidden="true"></span> Task List
                </NavLink>
            </li> */}
            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="/chat">
                <span className="oi oi-chat" aria-hidden="true"></span> Chat List
              </Link>
            </li>
            {/* <li className="nav-item px-3" onClick={closeNav}>
                <NavLink className="nav-link" to="alert/index">
                    <span className="oi oi-warning" aria-hidden="true"></span> Alert List
                </NavLink>
            </li>*@

            <li className="nav-item px-3" onClick={closeNav}>
              <Link className="nav-link" to="feedback/index">
                <span className="oi oi-envelope-closed" aria-hidden="true"></span> Feedback List
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  companies: state.companies,
  authMgr: state.authMgr,
  defaults: state.defaults,
  user: state.user,
});
export default connect(mapStateToProps)(App);
