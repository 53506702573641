import { takeEvery, put } from "redux-saga/effects";
import fenceService from "../../Services/fenceService";

function* insertFenceSaga(action) {
  try {
    let newFence = action.payload.fence;
    let newPoints = action.payload.points;
    const response = yield fenceService.insert(newFence);
    yield put({ type: "INSERT_FENCE_REDUCER", payload: response });
    for (let point of newPoints) {
      yield put({ type: "INSERT_FENCE_POINT", payload: point });
    }
  } catch (err) {
    console.log("error inserting fence", err);
  }
}

function* updateFenceSaga(action) {
  try {
    const response = yield fenceService.update(action.payload);
    yield put({ type: "UPDATE_FENCE_REDUCER", payload: response });
  } catch (err) {
    console.log("error updating fence", err);
  }
}
function* deleteFenceSaga(action) {
  try {
    yield fenceService.deleteFromServer(action.payload);
    yield put({ type: "DELETE_FENCE_REDUCER", payload: [action.payload.id] });
  } catch (err) {
    console.log("error updating fence", err);
  }
}

function* fencesSaga() {
  yield takeEvery("INSERT_FENCE", insertFenceSaga);
  yield takeEvery("UPDATE_FENCE", updateFenceSaga);
  yield takeEvery("DELETE_FENCE", deleteFenceSaga);
}

export default fencesSaga;
