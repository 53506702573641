const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result.split(","));
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
function downloadFromUrl(options) {
  const anchorElement = document.createElement("a");
  anchorElement.href = options.url;
  anchorElement.download = options.fileName ?? "";
  anchorElement.click();
  anchorElement.remove();
}

function downloadFromByteArray(options) {
  // Convert base64 string to numbers array.
  const numArray = atob(options.byteArray)
    .split("")
    .map((c) => c.charCodeAt(0));

  // Convert numbers array to Uint8Array object.
  const uint8Array = new Uint8Array(numArray);

  // Wrap it by Blob object.
  const blob = new Blob([uint8Array], { type: options.contentType });

  // Create "object URL" that is linked to the Blob object.
  const url = URL.createObjectURL(blob);

  // Invoke download helper function that implemented in
  // the earlier section of this article.
  downloadFromUrl({ url: url, fileName: options.fileName });

  // At last, release unused resources.
  URL.revokeObjectURL(url);
}

function getTotalGbStored(array) {
  let totalBytes = array.reduce((prev, current) => ({ fileSize: prev.fileSize + current.fileSize }));
  console.log(totalBytes.fileSize);
  let totalGb = (totalBytes.fileSize / 1024 / 1024 / 1024).toFixed(4);
  console.log("totalGb:", totalGb);
  return totalGb;
}

const fileHelper = {
  convertBase64,
  downloadFromByteArray,
  downloadFromUrl,
  getTotalGbStored,
};

export default fileHelper;
