const Logo = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 907.58 360"
      style={{ enableBackground: "new 0 0 907.58 360", color: "bacb2f", width: props.width, fill: "#bacb2f" }}
    >
      <g>
        <polygon
          className="st0"
          points="255.98,60.43 255.98,60.39 245.05,39.59 97.89,39.59 90.63,60.37 90.63,60.62 70.81,117.11
		112.81,103.81 204.81,103.81 208.81,107.61 88.81,320.41 166.81,320.41 172.81,282.41 272.81,92.41 	"
        />
        <g>
          <g>
            <path
              className="st0"
              d="M451.89,312.93c-3.89,3.89-11.57,7.07-17.07,7.07h-68c-5.5,0-13.18-3.18-17.07-7.07l-37.86-37.86
				c-3.89-3.89-7.07-11.57-7.07-17.07V102c0-5.5,3.18-13.18,7.07-17.07l37.86-37.86c3.89-3.89,11.57-7.07,17.07-7.07h68
				c5.5,0,13.18,3.18,17.07,7.07l37.86,37.86c3.89,3.89,7.07,11.57,7.07,17.07v156c0,5.5-3.18,13.18-7.07,17.07L451.89,312.93z
				 M436.82,118c0-5.5-1.8-13.6-4-18c-2.2-4.4-7.6-9.8-12-12c-4.4-2.2-12.5-4-18-4h-4c-5.5,0-13.6,1.8-18,4c-4.4,2.2-9.8,7.6-12,12
				c-2.2,4.4-4,12.5-4,18v120c0,5.5,1.8,13.6,4,18c2.2,4.4,7.6,9.8,12,12c4.4,2.2,12.5,4,18,4h4c5.5,0,13.6-1.8,18-4
				c4.4-2.2,9.8-7.6,12-12c2.2-4.4,4-12.5,4-18V118z"
            />
          </g>
          <path
            className="st0"
            d="M444.41,320.41h-87.17L304.83,268V92l52.41-52.41h87.17L496.82,92v176L444.41,320.41z M436.82,108l-8-16
			l-16-8h-24l-16,8l-8,16v140l8,16l16,8h24l16-8l8-16V108z"
          />
        </g>
        <polygon
          className="st0"
          points="560.84,283.46 548.84,320.41 628.84,320.41 616.84,283.46 616.84,113.88 616.84,39.59 556.84,39.59
		528.84,92.4 560.84,92.4 560.84,113.88 	"
        />
        <polygon
          className="st0"
          points="750.08,186.93 716.57,135.32 663.4,135.32 723.47,227.91 663.4,320.41 716.57,320.41 750.08,268.81
		783.6,320.41 836.77,320.41 776.64,227.91 836.77,135.32 783.54,135.41 	"
        />
      </g>
    </svg>
  );
};

export default Logo;
