import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import dateTimeModule from "../../../Modules/dateTime";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import LeftMapButtons from "../LeftMapButtons";

const NewContainer = (props) => {
  const { timeStamp } = props;
  const [bottom, setBottom] = useState(props.open ? "50px" : "-1000px");
  const animals = useSelector((state) => state.animals);
  const [animal, setAnimal] = useState("");
  const [showButtons, setShowButtons] = useState(true);
  const [openTab, setOpenTab] = useState("single");
  const companies = useSelector((state) => state.companies);
  const ranches = useSelector((state) => state.ranches);

  window.setShowButtons = setShowButtons;
  useEffect(() => {
    setBottom(props.open ? "50px" : "-550px");
  }, [props.open]);
  useEffect(() => console.log("showButtons", showButtons), [showButtons]);

  const handleLocationClick = () => {
    window.ReBuildLocationLayer();
    setShowButtons(false);
  };
  return (
    <div
      id="newContainer"
      className="dropdown-container"
      style={{ backgroundColor: "white", border: "solid", borderWidth: "0px", bottom: bottom }}
    >
      <button
        role="button"
        className="btn btn-outline-dark mapSidebarButton"
        onClick={() => window.hideFunction()}
        style={{ position: "absolute", top: "0px", right: "0px", width: "50px", height: "30px" }}
      >
        <span className="oi oi-x">X</span>
      </button>
      <br />
      <br />
      <div className="container-fluid ">
        {showButtons && (
          <div className="row justify-content-center">
            <div className="col-md-3 p-2 newcard">
              <div className="container-fluid text-center">
                <div className="m-2 ">Single Animal Tracking</div>

                <select className=" form-control" value={animal} onChange={(e) => setAnimal(e.target.value)}>
                  <option value="">Choose Animal</option>
                  {animals.map((animal) => (
                    <option key={animal.id} value={animal.id}>
                      Cattle # {animal.cattleNumber}
                    </option>
                  ))}
                </select>
                <br />
                <button
                  disabled={animal === ""}
                  className="btn btn-outline-dark"
                  onClick={() => window.LoadAnimalHistory(animal)}
                >
                  Load Tracking
                </button>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 p-2 newcard text-center">
              <button className="btn btn-outline-dark" onClick={handleLocationClick}>
                Load Tracking For All Animals
              </button>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-3 p-2 newcard">
              <div className="container-fluid text-center ">
                <button className="btn btn-outline-dark" onClick={window.LoadFenceHistory}>
                  Load Fence Heat Map
                </button>
              </div>
            </div>
          </div>
        )}
        <div>
          <LeftMapButtons timeStamp={timeStamp} />
        </div>
        {/* <div className="row">
          <div className="col-md-3">
            <br />
            <input
              id="newCompanyButton"
              type="button"
              className="btn btn-outline-dark btn-block"
              value="Company"
              onClick={() => window.AddCompany()}
            />
          </div>
          <div className="col-md-3">
            <br />
            <input
              id="newRanchButton"
              type="button"
              className="btn btn-outline-dark btn-block"
              value="Ranch"
              onClick={() => window.AddRanch()}
              disabled={companies.length < 1}
            />
          </div>
          <div className="col-md-3">
            <br />
            <input
              id="newSingleAnimalButton"
              type="button"
              className="btn btn-outline-dark btn-block"
              value="Single Animal"
              onClick={() => window.AddAnimal()}
              disabled={ranches.length < 1}
            />
          </div>
          <div className="col-md-3">
            <br />
            <button
              type="button"
              className="btn btn-outline-dark btn-block"
              onClick={() => {
                props.setShowMassCreateModal(true);
                window.hideFunction();
              }}
              disabled={ranches.length < 1}
            >
              Multiple Animals
            </button>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-3">
            <br />
            <input
              id="newFenceButton"
              type="button"
              className="btn btn-outline-dark btn-block"
              value="Fence"
              onClick={() => window.AddFence()}
              disabled={ranches.length < 1}
            />
          </div>
          <div className="col-md-3">
            <br />
            <input
              id="newPOIButton"
              type="button"
              className="btn btn-outline-dark btn-block"
              value="Point Of Interest"
              onClick={() => window.AddPOI()}
              disabled={ranches.length < 1}
            />
          </div>
          <div className="col-md-3">
            <br />
            <button
              id="newGroupButton"
              role="button"
              className="btn btn-outline-dark btn-block"
              disabled={ranches.length < 1}
            >
              Folder
            </button>
          </div>
        </div> */}
      </div>
      <br />
    </div>
  );
};

export default NewContainer;
