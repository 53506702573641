import dayjs from "dayjs";

const createCsv = (data, name) => {
  let headers = Object.keys(data[0]);
  let headerRow = headers.join(",");
  let dataRows = [];
  for (let item of data) {
    let newRow = Object.values(item);
    let joinedRow = newRow.join(",");
    dataRows.push(joinedRow);
  }
  let joinedBody = dataRows.join("\r\n");

  let fileOutput = `data:text/csv;charset=utf-8,${headerRow}\r\n${joinedBody}`;
  console.log(headerRow);
  console.log(joinedBody);
  console.log(fileOutput);

  let encodedUri = encodeURI(fileOutput);
  let link = document.createElement("a");
  let fileName = `${name}_${dayjs().format("MM_DD_YYYY")}.csv`;
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName);
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file named "my_data.csv".
};

function readFile(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

function parseFile(file) {
  let fileArray = file.split("\r\n");
  let headers;
  let data = [];
  for (let i = 0; i < fileArray.length; i++) {
    if (i === 0) {
      headers = fileArray[i].split(",");
    } else {
      let newData = {};
      let importData = fileArray[i].split(",");
      for (let j = 0; j < headers.length; j++) {
        newData[headers[j]] = importData[j];
      }
      data.push(newData);
    }
  }
  console.log("headers:", headers);
  console.log("data:", data);
  return { headers, data };
}

const csvHelper = {
  createCsv,
  readFile,
  parseFile,
};

export default csvHelper;
