export const baseUrl = "https://api.701x.com";
export const userName = "701developer";
export const password = "WeTrackCows!";
const token = window.btoa(userName + ":" + password);

export const config = {
  headers: {
    Authorization: "Basic " + token,
  },
};

export const animalType = ["BULL", "COW", "HEIFER", "STEER"];
export const genderType = ["M", "F"];
export const statusType = ["HEALTHY", "DISTRESS", "SICK", "DEAD"];
export const castrationMethod = ["CUT", "BANDED"];
export const PermissionType = [
  { name: "OWNER", value: 1 },
  { name: "RANCHER", value: 2 },
  { name: "HELPER", value: 3 },
  { name: "READ_ONLY", value: 4 },
  { name: "REPORTS_ONLY", value: 5 },
];
export const permissionNamesMap = {
  1: "OWNER",
  2: "RANCHER",
  3: "HELPER",
  4: "READ_ONLY",
  5: "REPORTS_ONLY",
};
export const logType = [
  "ABORTION",
  "ARTIFICIAL_INSEMIMNATION",

  "BANDING",
  "BRANDING",
  "BREEDING",
  "BROKEN_FENCE",

  "CALVING",
  "CASTRATE",
  "COMMENT",
  "CULLING",

  "DEHORNING",
  "DEHYDRATION",
  "DISTRESS",
  "DO_NOT_BREED",

  "EMBRYO_TRANSFER",
  "ESTRUS",

  "FEEDING",

  "HEALTH",
  "HEAT_STRESS",

  "MOVEMENT",
  "PREDATOR",
  "PREGNANCY_CHECK",
  "PREGNANCY_TEST",

  "RUNNING",
  "SHOCK",
  "SLEEPING",
  "STUCK",

  "TAG_ASSIGN_EID",
  "TAG_REMOVAL_EID",
  "TAG_TRANSFER_EID",

  "TAG_ASSIGN_701",
  "TAG_REMOVAL_701",
  "TAG_TRANSFER_701",

  "TAG_UPDATE_FIRMWARE",

  "THEFT",
  "TRANSFER",
  "TREATMENT",
  "TREATMENT_PHARMA_BASED",
  "TREATMENT_PHYSICAL_BASED",
  "TREATMENT_WEIGHT_BASED",

  "ULTRASOUND",

  "WEANING",
  "WEIGHING",
];

export const eventType = [
  "ABORTION",

  "BREEDING",
  "BROKEN_FENCE",

  "CALVING",

  "CULLING",

  "DEHYDRATION",
  "DISTRESS",
  "DRINKING",

  "ESTRUS",
  "FEEDING",

  "HEALTH",
  "HEAT_STRESS",

  "MOVEMENT",
  "POI",
  "PREDATOR",

  "RUNNING",

  "SHOCK",
  "SLEEPING",
  "STUCK",

  "TAG_REMOVAL",
  "THEFT",
];

export const poiType = [
  "ALLEY",
  "BARN",
  "BOG",
  "CHUTE",
  "CLIFF",
  "FEED_BUNK",
  "GATE",
  "HOUSE",
  "LAKE",
  "MINERALS",
  "MUD",
  "POND",
  "RIVER",
  "ROAD",
  "SHOP",
  "SHELTER",
  "WATER",
  "WATER_TANK",
  "WATER_DAM",
];

export const permissionType = [
  { name: "OWNER", value: 1 }, //Ranch
  { name: "RANCHER", value: 2 }, //POI
  { name: "HELPER", value: 3 }, //Animal
  { name: "READ_ONLY", value: 4 }, //Read only
  { name: "REPORTS_ONLY", value: 5 }, //Reports only
];

export const satisfactionType = [
  { name: "VERYBAD", value: 1 }, //Ranch
  { name: "BAD", value: 2 }, //POI
  { name: "OK", value: 3 }, //Animal
  { name: "GOOD", value: 4 }, //Read only
  { name: "GREAT", value: 5 }, //Reports only
];

export const breedType = [
  "ANGUS",

  "BEEFMASTER",
  "BRAHMAN",
  "BRANGUS",
  "BRAUNVIEH",

  "CHAROLAIS",
  "CHIANINA",
  "CORRIENTE",

  "GELBVIEH",

  "HEREFORD",

  "LIMOUSINE",

  "MAINE_ANJOU",

  "RED_ANGUS",

  "SALERS",
  "SANTA_GERTRUDIS",
  "SHORTHORN",
  "SIMMENTAL",

  "TARENTAISE",
  "TEXAS_LONGHORN",

  "OTHER",
];

export const StatusType = [
  "HEALTHY", //91-100
  "DISTRESS", //51-90
  "SICK", //1-50
  "DEAD", //0
];

export const initialTag = {
  id: null,
  companyId: null,
  farmId: null,
  animalId: null,
  networkNumber: null,
  code: null,
  description: null,
  eventTypeId: null,
  eventStatusId: null,
  longitude: null,
  latitude: null,
  altitude: null,
  heading: null,
  temperature: null,
  panel_Voltage: null,
  battery_Voltage: null,
  state_Of_Charge: null,
  softwareVersion: null,
  hardwareVersion: null,
  deviceSoftwareVersion: null,
  lastCheckInDate: null,
  lastUploadedDate: null,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  outOfFence: null,

  farmDescription: null,
  companyDescription: null,
  cattleNumber: null,
  cattleCode: null,
  cattleDescription: null,
  groupId: null,
  farmVirtualFenceId: null,

  isNew: false,
  isUpdate: false,
  isDelete: false,
};

export const initialAnimal = {
  id: null,
  companyId: null,
  farmId: null,
  groupId: null,
  farmVirtualFenceId: null,
  tagId: null,
  maleAnimalId: null,
  femaleAnimalId: null,
  fosterAnimalId: null,
  sires: null,
  type: null,
  code: null,
  description: null,
  cattleNumber: null,
  usdaNumber: null,
  sex: null,
  dob: null,
  age: null,
  isCalve: false,
  breed: null,
  breedDisplay: null,
  longitude: null,
  latitude: null,
  altitude: null,
  heading: null,
  temperature: null,
  eventTypeId: null,
  eventStatusId: null,
  isFreemartin: null,
  isCrastrated: null,
  crastrationMethod: null,
  doNotBreedFlag: null,
  lastCheckInDate: null,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  purchase_Date: null,
  enteredFarm_Date: null,
  sold_Date: null,
  death_Date: null,
  cull_Date: null,
  purchase_Price: null,
  sold_Price: null,
  weight: null,
  healthScale: null,
  heatScale: null,
  closeToCalvingPercentage: null,
  estimatedDateInseminated: null,
  actualDateInseminated: null,
  estimatedDateCalved: null,
  actualDateCalved: null,
  hasCalved: null,
  hasAborted: null,
  hasStillBorn: null,
  isOpen: null,
  outOfFence: null,
  calving_Ease_Direct: null,
  birth_Weight: null,
  weaning_Weight_Direct: null,
  yearling_Weight: null,
  yearling_Height: null,
  milk: null,
  maternal_Weaning_Weight: null,
  gestation_Length: null,
  calving_Ease_Maternal: null,
  mature_Daughter_Height: null,
  mature_Daughter_Weight: null,
  scrotal_Circumference: null,
  heifer_Pregnancy: null,
  udder: null,
  teat: null,
  carcass_Weight: null,
  percent_Retail_Cuts: null,
  marbling: null,
  imf: null,
  rib_Eye_Area: null,
  fat_Thickness: null,
  rump_Fat_Thickness: null,
  tenderness: null,
  days_To_Finish: null,
  residual_Average_Daily_Gain: null,
  residual_Feed_Intake: null,
  dry_Matter_Intake: null,
  stayability: null,
  maintenance_Energy: null,
  docility: null,
  companyDescription: null,
  farmDescription: null,
  groupDescription: null,
  farmVirtualFenceDescription: null,
  tagDescription: null,
  networkNumber: null,
  registrationNumber: null,
  maleCode: null,
  maleDescription: null,
  maleTagDescription: null,
  maleNetworkNumber: null,
  maleCattleNumber: null,
  femaleCode: null,
  femaleDescription: null,
  femaleTagDescription: null,
  femaleNetworkNumber: null,
  femaleCattleNumber: null,
  fosterCode: null,
  fosterDescription: null,
  fosterTagDescription: null,
  fosterNetworkNumber: null,
  fosterCattleNumber: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
};

export const initialCalvingRecord = {
  id: null,
  companyId: null,
  sires: null,
  calves: null,
  sireAnimalId: null,
  damAnimalId: null,
  calfAnimalId: null,
  closeToCalvingPercentage: null,
  estimatedDateInseminated: null,
  actualDateInseminated: null,
  estimatedDateCalved: null,
  actualDateCalved: null,
  hasCalved: false,
  hasAborted: false,
  hasStillBorn: false,
  isOpen: null,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  sireCode: null,
  sireDescription: null,
  sireCattleNumber: null,
  sireType: null,
  sireBreed: null,
  sireTagId: null,
  sireNetworkNumber: null,
  damCode: null,
  damDescription: null,
  damCattleNumber: null,
  damType: null,
  damBreed: null,
  damTagId: null,
  damNetworkNumber: null,
  calfCode: null,
  calfDescription: null,
  calfCattleNumber: null,
  calfType: null,
  calfBreed: null,
  calfTagId: null,
  calfNetworkNumber: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
};

export const initialLogState = {
  attachment: null,
  comment: "",
  companyId: null,
  createdByDate: null,
  createdByUserId: null,
  dosage: null,
  fileExtension: null,
  fileName: null,
  filePath: null,
  fileSize: 0,
  fileType: null,
  id: null,
  inActiveDate: null,
  isDelete: false,
  isNew: false,
  isUpdate: false,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  latitude: null,
  logDateTime: null,
  logTypeId: null,
  longitude: null,
  measurement: null,
  value: "",
};

export const allowedStorageLimit = 10;

export const initialGroup = {
  id: null,
  companyId: null,
  companyDescription: null,
  farmId: null,
  farmDescription: null,
  farmVirtualFenceId: null,
  fenceDescription: null,
  code: null,
  description: null,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
};

export const initialPoi = {
  id: null,
  companyId: null,
  farmId: null,
  farmVirtualFenceId: null,
  code: null,
  description: null,
  longitude: null,
  latitude: null,
  altitude: null,
  pins: null,
  size: null,
  radius_Miles: null,
  perimeter_Miles: null,
  area_SqMiles: null,
  area_Acres: null,
  warningLevel: null,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  companyDescription: null,
  farmDescription: null,
  farmVirtualFenceDescription: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
};

export const initialCompany = {
  id: null,
  userId: null,
  code: null,
  description: null,
  longitude: null,
  latitude: null,
  altitude: null,
  size: null,
  radius_Miles: null,
  perimeter_Miles: null,
  area_SqMiles: null,
  area_Acres: null,
  billAddress1: null,
  billAddress2: null,
  billAddress3: null,
  billCountry: null,
  billState: null,
  billRegion: null,
  billZip: null,
  billCity: null,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
};

export const initialRanch = {
  id: null,
  companyId: null,
  code: null,
  description: null,
  longitude: null,
  latitude: null,
  altitude: null,
  size: null,
  radius_Miles: null,
  perimeter_Miles: null,
  area_SqMiles: null,
  area_Acres: null,
  shipAddress1: null,
  shipAddress2: null,
  shipAddress3: null,
  shipCountry: null,
  shipState: null,
  shipRegion: null,
  shipZip: null,
  shipCity: null,
  calvingSeasonStartDate: null,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  companyDescription: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
};

export const initialFence = {
  id: null,
  companyId: null,
  farmId: null,
  code: null,
  description: null,
  longitude: null,
  latitude: null,
  altitude: null,
  pins: null,
  warningLevel: null,
  parimeter_Miles: null,
  perimeter_Miles: null,
  area_SqMiles: null,
  area_Acres: null,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  companyDescription: null,
  farmDescription: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
};

export const initialFencePoint = {
  id: null,
  companyId: null,
  farmVirtualFenceId: null,
  longitude: null,
  latitude: null,
  altitude: null,
  sequence: null,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
};

export const initialChat = {
  id: null,
  alertTypeId: null,
  chatContent: null,
  chatGroup: null,
  companyId: null,
  farmId: null,
  userId: null,
  animalId: null,
  groupId: null,
  farmVirtualFenceId: null,
  farmPointOfInterestId: null,
  hasBeenSeen: false,
  seenByDate: null,
  hasBeenClicked: false,
  clickedByDate: null,
  hasNotificationBeenSent: false,
  notificationSentByDate: null,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
};

export const allowedAnimalExportFields = {
  type: "type",
  code: "name",
  description: "description",
  cattleNumber: "Cattle Number",
  usdaNumber: "Eid Number",
  sex: "Sex",
  dob: "Date of Birth",
  age: "Age",
  breedDisplay: "Breed",
  longitude: "Longitude",
  latitude: "Latitude",
  altitude: "Altitude",
  heading: "Heading",
  temperature: "Temperature",
  eventTypeId: "Event Type",
  eventStatusId: "Event Status",
  isFreemartin: "Freemartin",
  isCrastrated: "Castrated",
  crastrationMethod: "Castration Method",
  doNotBreedFlag: "Do Not Breed",
  lastCheckInDate: "Last Check In",
  createdByDate: "Created Date",
  lastUpdatedByDate: "Last Updated Date",
  inActiveDate: "InActive Date",
  purchase_Date: "Purchase Date",
  enteredFarm_Date: "Entered Farm Date",
  sold_Date: "Sold Date",
  death_Date: "Death Date",
  cull_Date: "Cull Date",
  purchase_Price: "Purchase Price",
  sold_Price: "Sold Price",
  weight: "Weight",
  healthScale: "Health Scale",
  heatScale: "Heat Scale",
  closeToCalvingPercentage: "Close To Calving Percent",
  calving_Ease_Direct: "Calving Ease Direct",
  birth_Weight: "Birth Weight",
  weaning_Weight_Direct: "Weaning Weight Direct",
  yearling_Weight: "Yearling Weight",
  yearling_Height: "Yearling Height",
  milk: "Milk",
  maternal_Weaning_Weight: "Maternal Weaning Weight",
  gestation_Length: "Gestation Length",
  calving_Ease_Maternal: "Calving Ease Maternal",
  mature_Daughter_Height: "Mature Daughter Height",
  mature_Daughter_Weight: "Mature Daughter Weight",
  scrotal_Circumference: "Scrotal Circumfrence",
  heifer_Pregnancy: "Heifer Pregnancy",
  udder: "udder",
  teat: "Teat",
  carcass_Weight: "Carcass Weight",
  percent_Retail_Cuts: "Percent Retail Cuts",
  marbling: "Marbling",
  imf: "IMF",
  rib_Eye_Area: "Rib Eye Area",
  fat_Thickness: "Fat Thickness",
  rump_Fat_Thickness: "Rump Fat Thickness",
  tenderness: "Tenderness",
  days_To_Finish: "Days To Finish",
  residual_Average_Daily_Gain: "Residual Average Daily Gain",
  residual_Feed_Intake: "Residual Feed Intake",
  dry_Matter_Intake: "Dry Matter Intake",
  stayability: "Stayability",
  maintenance_Energy: "Maintenance Energy",
  docility: "Docility",
  companyDescription: "Company Description",
  farmDescription: "Farm Description",
  groupDescription: "Group Description",
  farmVirtualFenceDescription: "Fence Description",
  networkNumber: "Network Number",
  registrationNumber: "Registration Number",
  maleCode: "Sire Name",
  maleDescription: "Sire Description",
  maleNetworkNumber: "Sire Network Number",
  maleCattleNumber: "Sire Cattle Number",
  femaleCode: "Dam Name",
  femaleDescription: "Dam Description",
  femaleNetworkNumber: "Dam Network Number",
  femaleCattleNumber: "Dam Cattle Number",
  fosterCode: "Foster Name",
  fosterDescription: "Foster Description",
  fosterNetworkNumber: "Foster Network Number",
  fosterCattleNumber: "Foster Cattle Number",
};

export const initialCompanyUserRecord = {
  id: null,
  companyId: null,
  userId: null,
  defaultRanch: null,
  defaultGroup: null,
  defaultFence: null,
  defaultBreed: null,
  defaultBreedDisplay: null,
  permissionLevel: 0,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  companyCode: "COMPANY",
  companyDescription: null,
  userFirstName: null,
  userLastName: null,
  userOrganization: null,
  userTitle: null,
  userEmail: null,
  userPhone: null,
  userInActiveDate: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
};

export const satisfactionTypes = [
  { name: "VERYBAD", value: 1 },
  { name: "BAD", value: 2 },
  { name: "OK", value: 3 },
  { name: "GOOD", value: 4 },
  { name: "GREAT", value: 5 },
];

export const initialCell = {
  id: null,
  companyId: null,
  description: null,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
};

export const initialCellStrength = {
  id: null,
  cellId: null,
  companyId: null,
  timestamp: null,
  ttff: null,
  latitude: null,
  longitude: null,
  system_mode: null,
  rssi: null,
  rsrp: null,
  sinr: null,
  rsrq: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
};

export const initialCartMaster = {
  id: null,
  userId: null,
  farmId: null,
  companyId: null,
  shippingRateId: null,
  code: null,
  description: null,
  billAddress1: null,
  billAddress2: null,
  billAddress3: null,
  billCountry: null,
  billState: null,
  billRegion: null,
  billZip: null,
  billCity: null,
  shipAddress1: null,
  shipAddress2: null,
  shipAddress3: null,
  shipCountry: null,
  shipState: null,
  shipRegion: null,
  shipZip: null,
  shipCity: null,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  checkoutDate: null,
  inActiveDate: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
  isWishlist: false,
};

export const initialCartProduct = {
  id: null,
  cartId: null,
  stripeProductId: null,
  stripePricingId: null,
  quantity: 0,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  isNew: false,
  isUpdate: false,
  isDelete: false,
};
