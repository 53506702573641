const SliderField = (props) => {
	return (
		<div id="sliderField" style={{ position: "absolute", bottom: "45px", left: "0px", display: "none", zIndex: "800" }}>
			<div className="slidecontainer" style={{ position: "relative", width: "100vw" }}>
				<input id="myRange" type="range" min="1" max="500" defaultValue="50" className="slider" />
			</div>
		</div>
	);
};

export default SliderField;
