import { takeEvery, put } from "redux-saga/effects";
import signalRService from "../../Services/signalRService";
import { db } from "../../indexeddb/indexeddb";

function* joinCompanyGroup(action) {
  try {
    const defaultCompany = yield db.defaults.get("defaultCompany");
    yield signalRService.joinGroup(defaultCompany.value);
  } catch (err) {
    console.log("error joining defaultCompany", err);
  }
}

function* signalRSaga() {
  yield takeEvery("JOIN_COMPANY_GROUP", joinCompanyGroup);
}

export default signalRSaga;
