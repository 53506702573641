import { takeEvery, put } from "redux-saga/effects";
import companyService from "../../Services/companyService";

function* insertCompanySaga(action) {
  try {
    const response = yield companyService.insert(action.payload);
    yield put({ type: "INSERT_COMPANY_REDUCER", payload: response });
  } catch (err) {
    console.log("error inserting Company", err);
  }
}

function* updateCompanySaga(action) {
  try {
    const response = yield companyService.update(action.payload);
    yield put({ type: "UPDATE_COMPANY_REDUCER", payload: response });
  } catch (err) {
    console.log("error updating Company", err);
  }
}
function* deleteCompanySaga(action) {
  try {
    yield companyService.deleteFromServer(action.payload);
    yield put({ type: "DELETE_COMPANY_REDUCER", payload: [action.payload.id] });
  } catch (err) {
    console.log("error deleting Company", err);
  }
}

function* companySaga() {
  yield takeEvery("INSERT_COMPANY", insertCompanySaga);
  yield takeEvery("UPDATE_COMPANY", updateCompanySaga);
  yield takeEvery("DELETE_COMPANY", deleteCompanySaga);
}

export default companySaga;
