import { takeEvery, put } from "redux-saga/effects";
import fencePointService from "../../Services/fencePointService";
import { initialFencePoint } from "../../appSettings";

function* insertFencePointSaga(action) {
  try {
    const response = yield fencePointService.insert(action.payload);
    yield put({ type: "INSERT_FENCE_POINT_REDUCER", payload: response });
  } catch (err) {
    console.log("error inserting fence", err);
  }
}

function* insertFencePointFromMapSaga(action) {
  try {
    const response = yield fencePointService.insert({ ...initialFencePoint, ...action.payload });
    yield put({ type: "INSERT_FENCE_POINT_REDUCER", payload: response });
  } catch (err) {
    console.log("error inserting fence", err);
  }
}

function* updateFencePointSaga(action) {
  try {
    const response = yield fencePointService.update(action.payload);
    yield put({ type: "UPDATE_FENCE_POINT_REDUCER", payload: response });
  } catch (err) {
    console.log("error updating fence", err);
  }
}
function* deleteFencePointSaga(action) {
  try {
    yield fencePointService.deleteFromServer(action.payload);
    yield put({ type: "DELETE_FENCE_POINT_REDUCER", payload: [action.payload.id] });
  } catch (err) {
    console.log("error updating fence", err);
  }
}

function* fencePointSaga() {
  yield takeEvery("INSERT_FENCE_POINT", insertFencePointSaga);
  yield takeEvery("UPDATE_FENCE_POINT", updateFencePointSaga);
  yield takeEvery("INSERT_FENCE_POINT_FROM_MAP", insertFencePointFromMapSaga);
  yield takeEvery("DELETE_FENCE_POINT", deleteFencePointSaga);
}

export default fencePointSaga;
