import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dateTimeModule from "../../../Modules/dateTime";

const FenceHeatMap = (props) => {
  const [tagReportFromDateTime, setTagReportFromDateTime] = useState(dateTimeModule.currentUtcMinus(4));
  const [tagReportToDateTime, setTagReportToDateTime] = useState(dateTimeModule.currentUtc());
  const [tagReportSampleSize, setTagReportSampleSize] = useState(5);

  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
        padding: "10px",
        backgroundColor: "white",
        borderRadius: "10px",
        zIndex: "1002",
        display: "none",
      }}
    >
      <input type="button" value="Show/Hide" onClick={window.ShowHideFenceHeatMapLayer} />

      <div id="controlFenceHeatMapPanel">
        <div className="row">
          <div id="fenceHeatMapFromDateEditContainer" className="col-md-6 newcard" style={{ display: "block" }}>
            <label htmlFor="toReportFromDate">From Date/Time:</label>
            <br />
            <input
              type="datetime-local"
              value={dateTimeModule.dateTimePickerFormat(tagReportFromDateTime)}
              onChange={(e) => setTagReportFromDateTime(dateTimeModule.saveTimeStamp(e.target.value))}
              id="newfenceHeatMapFromDate"
              className="w-100"
            />
          </div>
          <div id="fenceHeatMapToDateEditContainer" className="col-md-6 newcard" style={{ display: "block" }}>
            <label htmlFor="fenceHeatMapToDate">To Date/Time:</label>
            <br />
            <input
              type="datetime-local"
              value={dateTimeModule.dateTimePickerFormat(tagReportToDateTime)}
              onChange={(e) => setTagReportToDateTime(dateTimeModule.saveTimeStamp(e.target.value))}
              id="newfenceHeatMapToDate"
              className="w-100"
            />
          </div>
          <div className="col-md-4 newcard" style={{ display: "block" }}>
            <label htmlFor="toReportSampleSize">Sample Size:</label>
            <br />
            <select
              id="myFenceHeatMapSampleSize"
              onChange={(e) => setTagReportSampleSize(e.target.value)}
              value={tagReportSampleSize}
            >
              <option value="1">1 Min.</option>
              <option value="5">5 Min.</option>
              <option value="15">15 Min.</option>
              <option value="30">30 Min.</option>
              <option value="60">60 Min.</option>
            </select>
          </div>
        </div>
        <input type="button" value="Refresh" onClick={window.ReLoadFenceHistory} />
      </div>
    </div>
  );
};

export default FenceHeatMap;
