import { takeEvery, put } from "redux-saga/effects";
import logService from "../../Services/logService";

function* insertLogsSaga(action) {
  try {
    const response = yield logService.insert(action.payload);
    yield put({ type: "INSERT_LOG_REDUCER", payload: response });
  } catch (err) {
    console.log("error inserting log", err);
  }
}
function* updateLogsSaga(action) {
  try {
    const response = yield logService.update(action.payload);
    yield put({ type: "UPDATE_LOG_REDUCER", payload: response });
  } catch (err) {
    console.log("error updating log", err);
  }
}
function* deleteLogsSaga(action) {
  try {
    yield logService.deleteFromServer(action.payload);
    yield put({ type: "DELETE_LOG_REDUCER", payload: [action.payload.id] });
  } catch (err) {
    console.log("error deleting log", err);
  }
}

function* logSaga() {
  yield takeEvery("INSERT_LOG", insertLogsSaga);
  yield takeEvery("UPDATE_LOG", updateLogsSaga);
  yield takeEvery("DELETE_LOG", deleteLogsSaga);
}

export default logSaga;
