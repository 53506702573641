import { all } from "redux-saga/effects";
import userSaga from "./user.saga";
import animalsSaga from "./animals.saga";
import syncSaga from "./sync.saga";
import defaultsSaga from "./defaults.saga";
import attachmentSaga from "./attachments.saga";
import animalAttachmentSaga from "./animalAttachments.saga";
import commentsSaga from "./comments.saga";
import animalCommentsSaga from "./animalComments.saga";
import animalLogsSaga from "./animalLog.saga";
import logSaga from "./log.saga";
import animalCalvingSaga from "./animalCalving.saga";
import tagSaga from "./tag.saga";
import groupsSaga from "./groups.saga";
import poisSaga from "./Pois.saga";
import fencesSaga from "./fences.saga";
import ranchSaga from "./ranch.saga";
import companySaga from "./companies.saga";
import signalRSaga from "./signalR.saga";
import fencePointSaga from "./fencePoint.saga";

// rootSaga is the primary saga.
// It bundles up all of the other sagas so our project can use them.
// This is imported in index.js as rootSaga

// some sagas trigger other sagas, as an example
// the registration triggers a login
// and login triggers setting the user
export default function* rootSaga() {
  yield all([
    userSaga(),
    animalsSaga(),
    syncSaga(),
    defaultsSaga(),
    attachmentSaga(),
    animalAttachmentSaga(),
    commentsSaga(),
    animalCommentsSaga(),
    animalLogsSaga(),
    logSaga(),
    animalCalvingSaga(),
    tagSaga(),
    groupsSaga(),
    poisSaga(),
    fencesSaga(),
    ranchSaga(),
    companySaga(),
    signalRSaga(),
    fencePointSaga(),
  ]);
}
