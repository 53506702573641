import { Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";

const Loading = (props) => {
  const loading = useSelector((state) => state.loading);
  return (
    <Modal show={loading.loading} onHide={() => {}} centered>
      <Modal.Header bsPrefix=" w-100 text-center">
        <Modal.Title>{loading.message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading.spinner && (
          <div className="text-center">
            <Spinner animation="border" style={{ color: "#bacb2f" }} />
          </div>
        )}
        {loading.progress && (
          <div>
            <ProgressBar now={loading.percent} label={`${loading.percent}%`} />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Loading;
