import { takeEvery, put } from "redux-saga/effects";
import animalCommentService from "../../Services/animalCommentService";

function* massInsertAnimalComments(action) {
  try {
    let insertedAnimalComments = [];
    for (let animalComment of action.payload) {
      const inserted = yield animalCommentService.insert(animalComment);
      insertedAnimalComments.push(inserted);
    }
    yield put({ type: "MASS_INSERT_ANIMAL_COMMENTS_REDUCER", payload: insertedAnimalComments });
  } catch (err) {
    console.log("error inserting attachment", err);
  }
}

function* massUpdateAnimalComments(action) {
  try {
    for (let animalComment of action.payload) {
      const updated = yield animalCommentService.update(animalComment);
      yield put({ type: "UPDATE_ANIMAL_COMMENTS_REDUCER", payload: updated });
    }
  } catch (err) {
    console.log("error inserting attachment", err);
  }
}

function* animalCommentsSaga() {
  yield takeEvery("MASS_INSERT_ANIMAL_COMMENT", massInsertAnimalComments);
  yield takeEvery("MASS_UPDATE_ANIMAL_COMMENTS", massUpdateAnimalComments);
}

export default animalCommentsSaga;
