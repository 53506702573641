import axios from "axios";
import dayjs from "dayjs";
import { db } from "../indexeddb/indexeddb";
import { baseUrl, config } from "../appSettings";
import { store } from "../index";
import dateTimeModule from "../Modules/dateTime";

const getAllAnimalsByCompany = (user, company) => {
  return axios.get(`${baseUrl}/Animal/AnimalDataByFilter?CompanyId=${company}`, config);
};

const getGrandFarmAnimals = () => {
  const grandFarm = "1c862017-30c4-47ab-ad95-64033b000000";//"1c862017-30c4-47ab-ad95-64033b0d6255";
  return axios.get(`${baseUrl}/Animal/AnimalDataByFilter?FarmId=${grandFarm}`, config);
};

const syncAnimals = async (lastSync, id, companyId) => {
  //get all animals from db
  let animals = await db.animals.toArray();
  //get current company from db

  console.log("animals from sync", animals);
  let newAnimals = animals.filter((a) => a.isNew === true);
  console.log("newAnimals", newAnimals);
  let updatedAnimals = animals.filter((a) => a.isUpdate === true && a.isNew === false);
  console.log("updatedAnimals", updatedAnimals);
  let deletedAnimals = animals.filter((a) => a.isDelete === true);

  for (let animal of newAnimals) {
    console.log(animal);
    insertNewAnimalFromSync(animal);
  }

  for (let animal of updatedAnimals) {
    updateAnimal(animal);
  }

  for (let animal of deletedAnimals) {
    deleteAnimal(animal);
  }
  let newAnimalsFromServer = await getNewAnimalsFromServer(lastSync, id, companyId);
  for (let newAnimal of newAnimalsFromServer.data) {
    let foundAnimal = await db.animals.get(newAnimal.id);
    console.log("foundAnimal:", foundAnimal);
    if (foundAnimal) {
      db.animals.put(newAnimal);
    } else {
      db.animals.add(newAnimal);
    }
  }
  // try {
  //   await db.animals.bulkAdd(newAnimalsFromServer.data);
  // } catch (err) {
  //   await db.animals.bulkPut(newAnimalsFromServer.data);
  // }
  let updatedAnimalsFromServer = await getUpdatedAnimalsFromServer(lastSync, id, companyId);
  await db.animals.bulkPut(updatedAnimalsFromServer.data);
  let deletedFromServer = await getDeletedAnimalsFromServer(lastSync, id, companyId);
  for (let deleted of deletedFromServer.data) {
    let found = await db.animals.get(deleted.id);
    if (found) {
      db.animals.delete(deleted.id);
    }
  }
  console.log(`deleted animals from server:`, deletedFromServer.data);
  console.log("new Animals from server", newAnimalsFromServer.data);
  console.log("updated Animals from server", updatedAnimalsFromServer.data);
  return await db.animals.toArray();
};

const insertNewAnimalFromSync = async (data) => {
  try {
    data.createdByDate = dateTimeModule.currentUtc();
    await axios.post(`${baseUrl}/Animal`, data, config);
    data.isNew = false;
    db.animals.put(data);
  } catch (err) {}
};

const insertAnimal = async (data) => {
  try {
    data.createdByDate = dateTimeModule.currentUtc();
    data.isNew = true;
    await db.animals.add(data);
    await axios.post(`${baseUrl}/Animal`, data, config);
    data.isNew = false;
    db.animals.put(data);
    return data;
  } catch (err) {
    return data;
  }
};

const updateAnimal = async (data) => {
  console.log("updateAnimal");
  try {
    data.lastUpdatedByDate = dateTimeModule.currentUtc();
    data.isUpdate = true;
    console.log(data);
    db.animals.put(data);
    await axios.put(`${baseUrl}/Animal/${data.id}`, data, config);
    console.log("after axios call");
    data.isUpdate = false;
    db.animals.put(data);
    return data;
  } catch (err) {
    return data;
  }
};

const deleteAnimal = async (data) => {
  try {
    data.inActiveDate = dateTimeModule.currentUtc();
    data.isDelete = true;
    db.animals.put(data);
    await axios.put(`${baseUrl}/Animal/${data.id}`, data, config);
    db.animals.delete(data.id);
  } catch (err) {}
};

const deleteFromServer = async (data) => {
  try {
    data.inActiveDate = dateTimeModule.currentUtc();
    data.isDelete = true;
    await db.animals.put(data);
    await axios.put(`${baseUrl}/Animal/${data.id}`, data, config);
    db.animals.delete(data.id);
    return data.id;
  } catch (err) {
    return data.id;
  }
};

const getNewAnimalsFromServer = async (lastUpdated, user, company) => {
  return axios.get(
    `${baseUrl}/Animal/AnimalDataByFilter?UserId=${user}&LessThenCreatedByDate=${encodeURIComponent(
      lastUpdated
    )}&CompanyId=${company}`,
    config
  );
};

const getUpdatedAnimalsFromServer = async (lastUpdated, user, company) => {
  return axios.get(
    `${baseUrl}/Animal/AnimalDataByFilter?UserId=${user}&LessThenUpdatedByDate=${encodeURIComponent(
      lastUpdated
    )}&CompanyId=${company}`,
    config
  );
};

const getDeletedAnimalsFromServer = async (lastUpdated, user, company) => {
  return axios.get(
    `${baseUrl}/Animal/AnimalDataByFilter?UserId=${user}&LessThenDeletedByDate=${encodeURIComponent(
      lastUpdated
    )}&CompanyId=${company}`,
    config
  );
};

const updateFromSignalR = async (data) => {
  let foundAnimal = await db.animals.get(data.id);
  console.log("foundAnimal:", foundAnimal);
  if (data.inActiveDate != null) {
    if (foundAnimal) {
      db.animals.delete(data.id);
      store.dispatch({ type: "MASS_DELETE_ANIMALS_REDUCER", payload: [data.id] });
    }
  } else if (foundAnimal) {
    db.animals.put(data);
    store.dispatch({ type: "MASS_UPDATE_ANIMALS_REDUCER", payload: [data] });
  } else {
    db.animals.add(data);
    store.dispatch({ type: "MASS_INSERT_ANIMALS_REDUCER", payload: [data] });
  }
};

const animalService = {
  getAllAnimalsByCompany: getAllAnimalsByCompany,
  syncAnimals: syncAnimals,
  update: updateAnimal,
  insert: insertAnimal,
  deleteFromServer: deleteFromServer,
  updateFromSignalR: updateFromSignalR,
  getGrandFarmAnimals,
};

export default animalService;
