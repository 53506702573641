import axios from "axios";
import dayjs from "dayjs";
import { db } from "../indexeddb/indexeddb";
import { baseUrl, config } from "../appSettings";
import { store } from "../index";
import dateTimeModule from "../Modules/dateTime";
import { v4 as uuid } from "uuid";

const url1 = "TagData";
const view = "TagDataByFilter?";
const reporting = "GetAllReportingTagDataByFilter?";
const tracking = "GetAllTrackingTagDataByFilter?";
const signal = "GetAllRSSITagDataByFilter?";
const grandFarm = "1c862017-30c4-47ab-ad95-64033b0d6255";
const dataPoint = {
  id: null,
  animalId: null,
  tagId: null,
  companyId: null,
  farmId: null,
  groupId: null,
  farmVirtualFenceId: null,
  time: null,
  timeUTC: 0,
  timeRTC: 0,
  longitude: 0,
  latitude: 0,
  altitude: 0,
  position_Uncertainty: 0,
  saT_Available: 0,
  accelVelocity: 0,
  accelXAxis: 0,
  accelYAxis: 0,
  accelZAxis: 0,
  gyroXAxis: 0,
  gyroYAxis: 0,
  gyroZAxis: 0,
  compXAxis: 0,
  compYAxis: 0,
  compZAxis: 0,
  temperature: 0,
  pressure: 0,
  humidity: 0,
  stressLevel: 0,
  eventStatusId: "string",
  eventTypes: "string",
  panel_Voltage: 0,
  battery_Voltage: 0,
  state_Of_Charge: 0,
  cell_RSSI: 0,
  gpS_TTFF: 0,
  cell_Debug_String: "string",
  gpS_Debug_String: "string",
  misc_String: "string",
  cummPanelInterruptCount: 0,
  cummPanelEnergyHarvested: 0,
  luX_Calibrated: 0,
  luX_White: 0,
  luX_Raw: 0,
  softwareVersion: "string",
  hardwareVersion: "string",
  createdByDate: "2021-09-24T18:45:51.828Z",
  createdByUserId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  lastUpdatedByDate: "2021-09-24T18:45:51.828Z",
  lastUpdatedByUserId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  inActiveDate: null,
  cattleNumber: null,
  networkNumber: null,
  timeUTCFormatted: null,
  isNew: true,
  isUpdate: true,
  isDelete: true,
};

async function getAllByFilter(
  top,
  tagId,
  animalId,
  farmVirtualFenceId,
  lessThenCreatedByDate,
  greaterThenCreatedByDate
) {
  let params = [];

  params.push(`FarmId=${grandFarm}`);
  if (top) {
    params.push(`top=${top}`);
  }
  if (tagId) {
    params.push(`TagId=${tagId}`);
  }
  if (animalId) {
    params.push(`AnimalId=${animalId}`);
  }
  if (farmVirtualFenceId) {
    params.push(`FarmVirtualFenceId=${farmVirtualFenceId}`);
  }
  if (lessThenCreatedByDate) {
    params.push(`LessThenCreatedByDate=${encodeURIComponent(lessThenCreatedByDate)}`);
  }
  if (greaterThenCreatedByDate) {
    params.push(`GreaterThenCreatedByDate=${encodeURIComponent(greaterThenCreatedByDate)}`);
  }
  let paramString = params.join("&");
  return axios.get(`${baseUrl}/${url1}/${view}${paramString}`, config);
}

async function getAllReportingTagDataByFilter(
  top,
  tagId,
  animalId,
  farmVirtualFenceId,
  lessThenCreatedByDate,
  greaterThenCreatedByDate,
  sampleSize
) {
  let params = [];

  params.push(`FarmId=${grandFarm}`);
  if (top) {
    params.push(`top=${top}`);
  }
  if (tagId) {
    params.push(`TagId=${tagId}`);
  }
  if (animalId) {
    params.push(`AnimalId=${animalId}`);
  }
  if (farmVirtualFenceId) {
    params.push(`FarmVirtualFenceId=${farmVirtualFenceId}`);
  }
  if (lessThenCreatedByDate) {
    params.push(`LessThenCreatedByDate=${encodeURIComponent(lessThenCreatedByDate)}`);
  }
  if (greaterThenCreatedByDate) {
    params.push(`GreaterThenCreatedByDate=${encodeURIComponent(greaterThenCreatedByDate)}`);
  }
  if (sampleSize) {
    params.push(`sampleSize=${encodeURIComponent(sampleSize)}`);
  }
  let paramString = params.join("&");
  return axios.get(`${baseUrl}/${url1}/${reporting}${paramString}`, config);
}

async function getAllTrackingTagDataByFilter(
  top,
  tagId,
  animalId,
  farmVirtualFenceId,
  lessThenCreatedByDate,
  greaterThenCreatedByDate,
  sampleSize,
  numberOfAnimals,
  interval
) {
  return new Promise((resolve, reject) => resolve(generateRandomPath(40, animalId, numberOfAnimals, interval)));
}

// async function getAllSignalTagDataByFilter(
//   top,
//   tagId,
//   animalId,
//   farmVirtualFenceId,
//   lessThenCreatedByDate,
//   greaterThenCreatedByDate,
//   sampleSize
// ) {
//   let params = [];

//   params.push(`CompanyId=${company.value}`);
//   if (top) {
//     params.push(`top=${top}`);
//   }
//   if (tagId) {
//     params.push(`TagId=${tagId}`);
//   }
//   if (animalId) {
//     params.push(`AnimalId=${animalId}`);
//   }
//   if (farmVirtualFenceId) {
//     params.push(`FarmVirtualFenceId=${farmVirtualFenceId}`);
//   }
//   if (lessThenCreatedByDate) {
//     params.push(`LessThenCreatedByDate=${encodeURIComponent(lessThenCreatedByDate)}`);
//   }
//   if (greaterThenCreatedByDate) {
//     params.push(`GreaterThenCreatedByDate=${encodeURIComponent(greaterThenCreatedByDate)}`);
//   }
//   if (sampleSize) {
//     params.push(`sampleSize=${encodeURIComponent(sampleSize)}`);
//   }
//   let paramString = params.join("&");
//   return axios.get(`${baseUrl}/${url1}/${signal}${paramString}`, config);
// }

function generateRandomPath(length, id, numberOfAnimals, interval) {
  let path = [];
  let startTime = dateTimeModule.currentUtcMinus(1);
  if (numberOfAnimals !== undefined) {
    length = length * numberOfAnimals * 10;
  }

  for (let i = 0; i < length; i++) {
    let newData = { ...dataPoint };
    let newPoint = generateRandomLatLon({ from: 46.718379, to: 46.72267 }, { from: -96.824387, to: -96.8287 }, 6);
    newData.id = uuid();
    newData.latitude = newPoint.latitude;
    newData.longitude = newPoint.longitude;
    newData.timeUTCFormatted = startTime;
    newData.animalId = id;
    startTime = dateTimeModule.dayjs(startTime).add(interval, "m");
    path.push(newData);
  }
  return { data: path };
}

function generateRandomLatLon(lat, lon, fixed) {
  let latitude = (Math.random() * (lat.to - lat.from) + lat.from).toFixed(fixed) * 1;
  let longitude = (Math.random() * (lon.to - lon.from) + lon.from).toFixed(fixed) * 1;

  return { latitude, longitude };
}

const tagDataService = {
  getAllByFilter,
  getAllReportingTagDataByFilter,
  getAllTrackingTagDataByFilter,
  generateRandomLatLon,
};
window.tagDataService = tagDataService;
export default tagDataService;
