import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dateTimeModule from "../../Modules/dateTime";
import { useUser } from "../../Modules/selectors";

const TagReport = (props) => {
  const user = useUser();
  const dispatch = useDispatch();
  const tagReport = useSelector((state) => state.tagReport);
  const [tagReportFromDateTime, setTagReportFromDateTime] = useState(dateTimeModule.currentUtcMinus(1));
  const [tagReportToDateTime, setTagReportToDateTime] = useState(dateTimeModule.currentUtc());
  const [tagReportSampleType, setTagReportSampleType] = useState("state_Of_Charge");
  const [tagReportSampleSize, setTagReportSampleSize] = useState("1 Min.");


  function handleClose() {
    dispatch({ type: "SET_SHOW", payload: false });
  }

  return (
    <Modal show={tagReport.show} onHide={handleClose} size="xl" centered>
      <Modal.Header bsPrefix=" w-100 text-center">
        <button
          type="button"
          id="closeTagReportModalXButton"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          style={{ margin: "5px" }}
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <Modal.Title>Tag Report</Modal.Title>
        {user.roleId === "Administrator" && window.currentTagId !== "" && <p>Tag GUID: {window.currentTagId}</p>}
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div id="tagReportFromDateEditContainer" className="col-md-6 newcard" style={{ display: "block" }}>
            <label htmlFor="toReportFromDate">From Date/Time:</label>
            <br />
            <input
              type="datetime-local"
              value={dateTimeModule.dateTimePickerFormat(tagReportFromDateTime)}
              onChange={(e) => setTagReportFromDateTime(dateTimeModule.saveTimeStamp(e.target.value))}
              id="newtagReportFromDate"
              className="w-100"
            />
          </div>
          <div id="tagReportToDateEditContainer" className="col-md-6 newcard" style={{ display: "block" }}>
            <label htmlFor="tagReportToDate">To Date/Time:</label>
            <br />
            <input
              type="datetime-local"
              value={dateTimeModule.dateTimePickerFormat(tagReportToDateTime)}
              onChange={(e) => setTagReportToDateTime(dateTimeModule.saveTimeStamp(e.target.value))}
              id="newTagReportToDate"
              className="w-100"
            />
          </div>
          <div className="col-md-4 newcard" style={{ display: "block" }}>
            <label htmlFor="toReportSampleField">Sample Field:</label>
            <br />
            <select
              className="w-100"
              id="myFieldsFromList"
              onChange={(e) => setTagReportSampleType(e.target.value)}
              value={tagReportSampleType}
            >
              <option value="state_Of_Charge">state_Of_Charge</option>
              <option value="panel_Voltage">panel_Voltage</option>
              <option value="battery_Voltage">battery_Voltage</option>

              <option value="accelXAxis">accelXAxis</option>
              <option value="accelYAxis">accelYAxis</option>
              <option value="accelZAxis">accelZAxis</option>

              <option value="gyroXAxis">gyroXAxis</option>
              <option value="gyroYAxis">gyroYAxis</option>
              <option value="gyroZAxis">gyroZAxis</option>

              <option value="compXAxis">compXAxis</option>
              <option value="compYAxis">compYAxis</option>
              <option value="compZAxis">compZAxis</option>


            <option value="cell_RSSI">cell_RSSI</option>
            <option value="gpS_TTFF">gpS_TTFF</option>
            <option value="temperature">temperature</option>
            <option value="pressure">pressure</option>
            <option value="altitude">altitude</option>
		    </select>
          </div>  
          <div className="col-md-4 newcard" style={{ display: "block" }}>
          <label htmlFor="toReportSampleSize">Sample Size:</label>
              <br />
            <select id="mySampleSizeFromList" 
                 onChange={(e) => setTagReportSampleSize(e.target.value)}
                 value={tagReportSampleSize}>
            <option value="1">1 Min.</option>
            <option value="5">5 Min.</option>
            <option value="15">15 Min.</option>
            <option value="30">30 Min.</option>
            <option value="60">60 Min.</option>
            </select>
          </div>
        </div>
        <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => window.addFieldFromList()}>
          Add
        </button>
        <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => window.clearFieldFromList()}>
          Clear
        </button>
        <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => window.searchFieldFromList()}>
          Refresh
        </button>
        <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => window.exportFieldFromList()}>
          Export
        </button>

        <div className="chart-container" style={{ position: "relative", height: "75vh" }}>
          <canvas id="myChart"></canvas>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default TagReport;
