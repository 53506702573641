const initialTagReportState = {
  show: false
};

const tagReportReducer = (state = initialTagReportState, action) => {
  switch (action.type) {
    case "SET_SHOW": {
      return { show: action.payload };
    }
    default:
      return state;
  }
};

export default tagReportReducer;
