import { useEffect, useState } from "react";
import SettingsContainer from "./BottomDockContainers/SettingsContainer";
import SearchContainer from "./BottomDockContainers/SearchContainer";
import FilterContainer from "./BottomDockContainers/FilterContainer";
import GoToContainer from "./BottomDockContainers/GoToContainer";
import NewContainer from "./BottomDockContainers/NewContainer";
import CalvingContainer from "./BottomDockContainers/CalvingContainer/CalvingContainer";
import { useSelector } from "react-redux";

const BottomDock = (props) => {
  const { timeStamp } = props;
  const mapState = useSelector((state) => state.mapState);

  const [openContainer, setOpenContainer] = useState("none");

  const toggleOpenContainer = (container) => {
    // console.log("toggleOpenContainer()", container);
    if (container === openContainer) {
      setOpenContainer("none");
    } else {
      setOpenContainer(container);
    }
  };
  useEffect(() => {
    if (mapState.map?.ready) {
      window.showFunction = toggleOpenContainer;
      window.hideFunction = toggleOpenContainer;
      // window.setUpHideFunction();
      // return () => window.removeHideFunction();
    }
  }, [mapState]);

  return (
    <>
      <div
        className=""
        id="myButtons"
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          display: "block",
          width: "100%",
          height: "50px",
          overflow: "hidden",
          zIndex: "1002",
        }}
      >
        <div style={{ position: "relative", width: "100%", height: "100%" }} className="row m-0">
          <div
            id="newContainerDiv"
            className={`col text-center p-2 bottomNav ${openContainer === "newContainer" ? "bottomNavActive" : ""}`}
            style={{ width: "16%", cursor: "pointer" }}
            onClick={() => toggleOpenContainer("newContainer")}
          >
            Animal Tracking
          </div>
        </div>
      </div>

      <NewContainer
        open={openContainer === "newContainer"}
        setShowMassCreateModal={props.setShowMassCreateModal}
        timeStamp={timeStamp}
      />
    </>
  );
};

export default BottomDock;
