import { takeEvery, put } from "redux-saga/effects";
import ranchService from "../../Services/ranchService";

function* insertRanchSaga(action) {
  try {
    const response = yield ranchService.insert(action.payload);
    yield put({ type: "INSERT_RANCH_REDUCER", payload: response });
  } catch (err) {
    console.log("error inserting Ranch", err);
  }
}

function* updateRanchSaga(action) {
  try {
    const response = yield ranchService.update(action.payload);
    yield put({ type: "UPDATE_RANCH_REDUCER", payload: response });
  } catch (err) {
    console.log("error updating Ranch", err);
  }
}

function* deleteRanchSaga(action) {
  try {
    yield ranchService.deleteFromServer(action.payload);
    yield put({ type: "DELETE_RANCH_REDUCER", payload: [action.payload.id] });
  } catch (err) {
    console.log("error deleting Ranch", err);
  }
}

function* ranchSaga() {
  yield takeEvery("INSERT_RANCH", insertRanchSaga);
  yield takeEvery("UPDATE_RANCH", updateRanchSaga);
  yield takeEvery("DELETE_RANCH", deleteRanchSaga);
}

export default ranchSaga;
