const defaultFenceList = [
  {
    id: "e650eaf0-bdb6-4b7e-b47d-16ce2122ef62",
    companyId: "80b2ebaa-9ddf-48e5-841b-945eb29ad00d",
    farmId: "59eb07d5-c6f0-48c7-b173-d577da690b0f",
    code: "test",
    description: "testPublicFence",
    type: null,
    longitude: -96.830773,
    latitude: 46.723438,
    altitude: null,
    pins: null,
    warningLevel: null,
    parimeter_Miles: null,
    perimeter_Miles: 1.27,
    area_SqMiles: 0.1,
    area_Acres: 64.48,
    createdByDate: "2021-09-24T18:12:44+00:00",
    createdByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    lastUpdatedByDate: "2021-09-27T13:47:55+00:00",
    lastUpdatedByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    inActiveDate: null,
    companyDescription: "Cote Company",
    farmDescription: "test1",
    isNew: false,
    isUpdate: false,
    isDelete: false,
  },
];

const fences = (state = defaultFenceList, action) => {
  switch (action.type) {
    case "SET_FENCES": {
      let sorted = [...action.payload].sort((a, b) =>
        a.description > b.description ? 1 : b.description > a.description ? -1 : 0
      );
      return sorted;
    }
    case "UPDATE_FENCE_REDUCER": {
      let updatedFences = state.map((fence) => (fence.id === action.payload.id ? action.payload : fence));
      return updatedFences;
    }
    case "INSERT_FENCE_REDUCER": {
      return [...state, action.payload];
    }
    case "DELETE_FENCE_REDUCER": {
      let fencePoints = action.payload;
      let newState = [...state];
      return newState.filter((fencePoint) => fencePoints.indexOf(fencePoint.id) === -1);
    }
    default:
      return state;
  }
};

export default fences;
