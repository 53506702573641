const initialLoadingState = {
  loading: false,
  spinner: false,
  progress: false,
  percent: 0,
  message: "",
};

const loadingReducer = (state = initialLoadingState, action) => {
  switch (action.type) {
    case "SET_LOADING": {
      return { loading: true, spinner: true, progress: false, percent: 0, message: action.payload };
    }
    case "SET_LOADING_WITH_PROGRESS": {
      return { loading: true, spinner: true, progress: true, percent: 0, message: action.payload };
    }
    case "RESET_LOADING": {
      return initialLoadingState;
    }
    case "SET_PERCENT_LOADING": {
      return { ...state, percent: action.payload };
    }
    default:
      return state;
  }
};

export default loadingReducer;
