import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import dateTimeModule from "../../Modules/dateTime";
import tagDataService from "../../Services/tagDataService";
import tagService from "../../Services/tagService";
import cellStrengthService from "../../Services/cellStrengthService";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

//components and pages imports
import Nav from "../Nav/Nav";
import Map from "../Map/Map";

import Logo from "../Shared/Svg/701x";

import Loading from "../Loading/Loading";
import TagReport from "../TagReport/TagReport";

const App = (props) => {
  window.tagDataService = tagDataService;
  window.dispatchFromMap = props.dispatch;
  window.tagService = tagService;
  window.cellStrengthService = cellStrengthService;

  return (
    <Router>
      <div className="hideMap unselectable" id="myMap"></div>
      <div
        style={{
          zIndex: "1000",
          fontSize: "24px",
          textAlign: "center",
          position: "relative",
          width: "150px",
          marginLeft: "50%",
          transform: "translate(-50%)",
        }}
      >
        <Logo width="150px" />
      </div>
      <Loading />
      <TagReport />
      <div style={{ height: "30px" }}></div>
      <Switch>
        <Route path="/map" component={Map} />
        <Route path="/" component={Map} />
      </Switch>
    </Router>
  );
};

export default connect()(App);
