import { IoPlayOutline, IoPauseOutline, IoRefreshOutline } from "react-icons/io5";
import { useState } from "react";
import dateTimeModule from "../../Modules/dateTime";
import { useUser } from "../../Modules/selectors";

const LeftMapButtons = (props) => {
  const user = useUser();
  const [heatMapDates, setHeatMapDates] = useState(null);
  window.setHeatMapDates = setHeatMapDates;
  return (
    <div
      id="ClearTrackingToggleVisible"
      style={{
        padding: "10px",
        backgroundColor: "white",
        borderRadius: "10px",
        zIndex: "1002",
        display: "none",
      }}
      className="text-center"
    >
      {props.timeStamp && <span>{dateTimeModule.displayTimeStamp(props.timeStamp)}</span>}
      <div id="locationToolSelection" style={{ display: "none" }}>
        <button className="btn btn-outline-dark m-1" onClick={window.PauseLocationLayer}>
          <IoPauseOutline />
        </button>
        <button className="btn btn-outline-dark m-1" onClick={window.PlayLocationLayer}>
          <IoPlayOutline />
        </button>
        <button className="btn btn-outline-dark m-1" onClick={window.ResetLocationLayer}>
          <IoRefreshOutline />
        </button>
      </div>
      <div id="fenceHeatMapSelection" style={{ display: "none" }}>
        {heatMapDates && (
          <span>
            {dateTimeModule.displayTimeStamp(heatMapDates.from)} - {dateTimeModule.displayTimeStamp(heatMapDates.to)}
          </span>
        )}
      </div>
      <div id="trackingToolSelection" style={{ display: "none" }}>
        <button className="btn btn-outline-dark m-1" onClick={window.pauseAnimalHistoryAnimation}>
          <IoPauseOutline />
        </button>
        <button className="btn btn-outline-dark m-1" onClick={window.playAnimalHistoryAnimation}>
          <IoPlayOutline />
        </button>
        <button className="btn btn-outline-dark m-1" onClick={window.resetAnimalHistoryAnimation}>
          <IoRefreshOutline />
        </button>
      </div>

      <div style={{}}>
        <button className="btn btn-outline-dark " onClick={window.ClearTracking}>
          <span className="oi oi-trash">Clear Tracking</span>
        </button>
      </div>
    </div>
  );
};

export default LeftMapButtons;
