import axios from "axios";
import dayjs from "dayjs";
import { put, takeLatest } from "redux-saga/effects";
import { db } from "../../indexeddb/indexeddb";
import companyUserService from "../../Services/companyUserService";
// worker Saga: will be fired on "FETCH_USER" actions
function* updateDefaults(action) {
  try {
    console.log("updating defaults");
    const companyUser = yield db.companyUser.toArray();
    let userDefaults = companyUser[0];
    console.log("companyUser", companyUser);
    userDefaults.defaultRanch = action.payload.ranch;
    userDefaults.defaultFence = action.payload.fence;
    userDefaults.defaultGroup = action.payload.group;
    userDefaults.defaultBreed = action.payload.breed;
    userDefaults.defaultBreedDisplay = action.payload.breedDisplay;
    console.log("updatedDefaults", userDefaults);
    yield put({ type: "SET_DEFAULTS", payload: action.payload });
    yield companyUserService.update(userDefaults);
  } catch (error) {}
}

function* companyUserSaga() {
  yield takeLatest("UPDATE_DEFAULTS", updateDefaults);
}

export default companyUserSaga;
