import { put, takeLatest } from "redux-saga/effects";
import companyService from "../../Services/companyService";
import animalService from "../../Services/animalService";
import fenceService from "../../Services/fenceService";
import fencePointService from "../../Services/fencePointService";
import poiService from "../../Services/poiService";
import poiPointService from "../../Services/poiPointService";
import groupService from "../../Services/groupService";
import ranchService from "../../Services/ranchService";
import attachmentService from "../../Services/attachmentService";
import animalAttachmentService from "../../Services/animalAttachmentService";
import animalCalvingService from "../../Services/animalCalvingService";
import animalLogService from "../../Services/animalLogService";
import { db } from "../../indexeddb/indexeddb";
import logService from "../../Services/logService";
import tagService from "../../Services/tagService";
import commentService from "../../Services/commentService";
import animalCommentService from "../../Services/animalCommentService";
import companyUserService from "../../Services/companyUserService";
import signalRService from "../../Services/signalRService";
import dateTimeModule from "../../Modules/dateTime";

// worker Saga: will be fired on "FETCH_USER" actions
function* fullPullByUser(action) {
  try {
    let user = action.payload;
    const currentSyncDate = dateTimeModule.currentUtc();
    console.log("fullpullsaga()");
    const companies = yield companyService.getAllByUser(user);
    yield db.companies.bulkPut(companies.data);
    db.defaults.add({ name: "defaultCompany", value: companies.data[0].id });
    yield put({ type: "SET_DEFAULT", payload: { company: companies.data[0].id } });
    const companyUser = yield companyUserService.getAllByCompanyAndUser(user, companies.data[0].id);
    let userDefaults = companyUser.data[0];
    yield db.companyUser.add(userDefaults);
    yield put({
      type: "SET_DEFAULT",
      payload: {
        breed: userDefaults.defaultBreed,
        breedDisplay: userDefaults.defaultBreedDisplay,
        fence: userDefaults.defaultFence,
        group: userDefaults.defaultGroup,
        ranch: userDefaults.defaultRanch,
      },
    });
    yield put({ type: "SET_COMPANIES", payload: companies.data });
    let company = companies.data[0].id;
    //get all animals by user and defaultCompany;
    const animals = yield animalService.getAllAnimalsByCompany(user, company);
    yield db.animals.bulkAdd(animals.data);
    yield put({ type: "SET_ANIMALS", payload: animals.data });
    //get all fences by user and defaultCompany
    const fences = yield fenceService.getAllByCompany(user, company);
    yield db.fences.bulkAdd(fences.data);
    yield put({ type: "SET_FENCES", payload: fences.data });
    //get all fencePoints by user and defaultCompany
    const fencePoints = yield fencePointService.getAllByCompany(user, company);
    yield db.fencePoints.bulkAdd(fencePoints.data);
    yield put({ type: "SET_FENCE_POINTS", payload: fencePoints.data });
    //get all POI's by user and defaultCompany
    const pois = yield poiService.getAllByCompany(user, company);
    yield db.pois.bulkAdd(pois.data);
    yield put({ type: "SET_POIS", payload: pois.data });
    //get all PoiPoints by user and company
    const poiPoints = yield poiPointService.getAllByCompany(user, company);
    yield db.poiPoints.bulkAdd(poiPoints.data);
    yield put({ type: "SET_POI_POINTS", payload: poiPoints.data });
    //get all groups by user and company
    const groups = yield groupService.getAllByCompany(user, company);
    yield db.groups.bulkAdd(groups.data);
    yield put({ type: "SET_GROUPS", payload: groups.data });
    //get all ranches by user and company
    const ranches = yield ranchService.getAllByCompany(user, company);
    yield put({ type: "SET_RANCHES", payload: ranches.data });
    yield db.ranches.bulkAdd(ranches.data);
    //get all attachments by user and company
    const attachments = yield attachmentService.getAllByCompany(user, company);
    yield put({ type: "SET_ATTACHMENTS", payload: attachments.data });
    yield db.attachments.bulkAdd(attachments.data);
    //get all animalAttachments by user and company
    const animalAttachments = yield animalAttachmentService.getAllByCompany(user, company);
    yield put({ type: "SET_ANIMAL_ATTACHMENTS", payload: animalAttachments.data });
    yield db.animalAttachments.bulkAdd(animalAttachments.data);
    //get all animalCalvings by user and company
    const animalCalvings = yield animalCalvingService.getAllByCompany(user, company);
    yield put({ type: "SET_ANIMAL_CALVINGS", payload: animalCalvings.data });
    yield db.animalCalvings.bulkAdd(animalCalvings.data);
    //get all logs by user and company
    const logs = yield logService.getAllByCompany(user, company);
    yield put({ type: "SET_LOGS", payload: logs.data });
    yield db.logs.bulkAdd(logs.data);
    //get all animalLogs by user and company
    const animalLogs = yield animalLogService.getAllByCompany(user, company);
    yield put({ type: "SET_ANIMAL_LOGS", payload: animalLogs.data });
    yield db.animalLogs.bulkAdd(animalLogs.data);
    //get all tags by user and company
    const tags = yield tagService.getAllByCompany(user, company);
    yield put({ type: "SET_TAGS", payload: tags.data });
    yield db.tags.bulkAdd(tags.data);
    //get all comments by user and company
    const comments = yield commentService.getAllByCompany(user, company);
    yield put({ type: "SET_COMMENTS", payload: comments.data });
    yield db.comments.bulkAdd(comments.data);
    //get all animalComments by user and company
    const animalComments = yield animalCommentService.getAllByCompany(user, company);
    yield put({ type: "SET_ANIMAL_COMMENTS", payload: animalComments.data });
    yield db.animalComments.bulkAdd(animalComments.data);
    //update sync date to current sync time;
    console.log("updating lastSync:", currentSyncDate);
    db.defaults.add({ name: "lastsync", value: currentSyncDate });
    yield signalRService.startConnection();
    //signalRService.joinGroup(company);
  } catch (error) {
    console.log("full pull error:", error);
  }
}

function* fullPullByCompany(action) {
  try {
    yield put({ type: "SET_LOADING_WITH_PROGRESS", payload: "Loading Company Data" });

    const currentSyncDate = dateTimeModule.currentUtc();
    const company = action.payload.company;
    const user = action.payload.user;
    signalRService.stopConnection();

    //update default company in db
    yield db.defaults.put({ name: "defaultCompany", value: company });
    //update default company in redux store
    yield put({ type: "SET_DEFAULT", payload: { company: company } });
    const companyUser = yield companyUserService.getAllByCompanyAndUser(user, company);
    let userDefaults = companyUser.data[0];
    yield db.companyUser.clear();
    yield db.companyUser.add(userDefaults);
    yield put({
      type: "SET_DEFAULT",
      payload: {
        breed: userDefaults.defaultBreed,
        breedDisplay: userDefaults.defaultBreedDisplay,
        fence: userDefaults.defaultFence,
        group: userDefaults.defaultGroup,
        ranch: userDefaults.defaultRanch,
      },
    });

    console.log("companyUser:", companyUser.data);
    //get all animals for new default company
    const animals = yield animalService.getAllAnimalsByCompany(user, company);
    yield put({ type: "SET_ANIMALS", payload: animals.data });
    yield db.animals.clear();
    yield db.animals.bulkAdd(animals.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 10 });

    //get all fences for new default company
    const fences = yield fenceService.getAllByCompany(user, company);
    yield put({ type: "SET_FENCES", payload: fences.data });
    yield db.fences.clear();
    yield db.fences.bulkAdd(fences.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 15 });

    //get all companies again for user to make sure companies are up to date
    const companies = yield companyService.getAllByUser(user);
    yield put({ type: "SET_COMPANIES", payload: companies.data });
    yield db.companies.clear();
    yield db.companies.bulkAdd(companies.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 20 });

    const fencePoints = yield fencePointService.getAllByCompany(user, company);
    yield put({ type: "SET_FENCE_POINTS", payload: fencePoints.data });
    yield db.fencePoints.clear();
    yield db.fencePoints.bulkAdd(fencePoints.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 25 });

    const pois = yield poiService.getAllByCompany(user, company);
    yield put({ type: "SET_POIS", payload: pois.data });
    yield db.pois.clear();
    yield db.pois.bulkAdd(pois.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 30 });

    const poiPoints = yield poiPointService.getAllByCompany(user, company);
    yield put({ type: "SET_POI_POINTS", payload: poiPoints.data });
    yield db.poiPoints.clear();
    yield db.poiPoints.bulkAdd(poiPoints.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 35 });

    const groups = yield groupService.getAllByCompany(user, company);
    yield put({ type: "SET_GROUPS", payload: groups.data });
    yield db.groups.clear();
    yield db.groups.bulkAdd(groups.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 40 });

    //get all ranches by user and company
    const ranches = yield ranchService.getAllByCompany(user, company);
    yield put({ type: "SET_RANCHES", payload: ranches.data });
    yield db.ranches.clear();
    yield db.ranches.bulkAdd(ranches.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 45 });

    //get all attachments by user and company
    const attachments = yield attachmentService.getAllByCompany(user, company);
    yield put({ type: "SET_ATTACHMENTS", payload: attachments.data });
    yield db.attachments.clear();
    yield db.attachments.bulkAdd(attachments.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 50 });

    //get all animalAttachments by user and company
    const animalAttachments = yield animalAttachmentService.getAllByCompany(user, company);
    yield put({ type: "SET_ANIMAL_ATTACHMENTS", payload: animalAttachments.data });
    yield db.animalAttachments.clear();
    yield db.animalAttachments.bulkAdd(animalAttachments.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 55 });

    //get all animalCalvings by user and company
    const animalCalvings = yield animalCalvingService.getAllByCompany(user, company);
    yield put({ type: "SET_ANIMAL_CALVINGS", payload: animalCalvings.data });
    yield db.animalCalvings.clear();
    yield db.animalCalvings.bulkAdd(animalCalvings.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 60 });

    //get all logs by user and company
    const logs = yield logService.getAllByCompany(user, company);
    yield put({ type: "SET_LOGS", payload: logs.data });
    yield db.logs.clear();
    yield db.logs.bulkAdd(logs.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 65 });

    //get all animalLogs by user and company
    const animalLogs = yield animalLogService.getAllByCompany(user, company);
    yield put({ type: "SET_ANIMAL_LOGS", payload: animalLogs.data });
    yield db.animalLogs.clear();
    yield db.animalLogs.bulkAdd(animalLogs.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 70 });

    //get all tags by user and company
    const tags = yield tagService.getAllByCompany(user, company);
    yield put({ type: "SET_TAGS", payload: tags.data });
    yield db.tags.clear();
    yield db.tags.bulkAdd(tags.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 75 });

    //get all comments by user and company
    const comments = yield commentService.getAllByCompany(user, company);
    yield put({ type: "SET_COMMENTS", payload: comments.data });
    yield db.comments.clear();
    yield db.comments.bulkAdd(comments.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 80 });

    //get all animalComments by user and company
    const animalComments = yield animalCommentService.getAllByCompany(user, company);
    yield put({ type: "SET_ANIMAL_COMMENTS", payload: animalComments.data });
    yield db.animalComments.clear();
    yield db.animalComments.bulkAdd(animalComments.data);
    yield put({ type: "SET_PERCENT_LOADING", payload: 85 });

    console.log("updating lastSync:", currentSyncDate);
    db.defaults.put({ name: "lastsync", value: currentSyncDate });
    yield signalRService.shouldConnect();
    yield put({ type: "RESET_LOADING" });

    //signalRService.joinGroup(company);
  } catch (err) {}
}

function* syncUserData(action) {
  try {
    let user = action.payload;
    let currentSyncDate = dateTimeModule.currentUtc();
    const lastsyncObject = yield db.defaults.get("lastsync");
    const lastSync = lastsyncObject.value;
    const currentCompany = yield db.defaults.get("defaultCompany");
    let company = currentCompany.value;
    console.log("currentCompany", currentCompany);
    yield put({ type: "SET_DEFAULT", payload: { company: company } });
    const animals = yield animalService.syncAnimals(lastSync, user, company);

    yield put({ type: "SET_PERCENT_LOADING", payload: 10 });

    const companies = yield companyService.sync(lastSync, user);
    yield put({ type: "SET_PERCENT_LOADING", payload: 15 });
    const fences = yield fenceService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 20 });

    const fencePoints = yield fencePointService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 25 });

    const pois = yield poiService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 30 });
    const poiPoints = yield poiPointService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 35 });

    const groups = yield groupService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 40 });

    const ranches = yield ranchService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 45 });

    const attachments = yield attachmentService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 50 });

    const animalAttachments = yield animalAttachmentService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 55 });

    const animalCalvings = yield animalCalvingService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 60 });

    const animalLogs = yield animalLogService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 65 });

    const logs = yield logService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 75 });

    const tags = yield tagService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 85 });

    const comments = yield commentService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 90 });

    const animalComments = yield animalCommentService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 95 });

    const companyUser = yield companyUserService.sync(lastSync, user, company);
    yield put({ type: "SET_PERCENT_LOADING", payload: 100 });

    let userDefaults = companyUser[0];
    yield put({
      type: "SET_DEFAULT",
      payload: {
        breed: userDefaults.defaultBreed,
        breedDisplay: userDefaults.defaultBreedDisplay,
        fence: userDefaults.defaultFence,
        group: userDefaults.defaultGroup,
        ranch: userDefaults.defaultRanch,
      },
    });
    if (animals) {
      yield put({ type: "SET_ANIMALS", payload: animals });
    }

    if (companies) {
      yield put({ type: "SET_COMPANIES", payload: companies });
    }
    if (fences) {
      yield put({ type: "SET_FENCES", payload: fences });
    }
    if (fencePoints) {
      yield put({ type: "SET_FENCE_POINTS", payload: fencePoints });
    }
    if (pois) {
      yield put({ type: "SET_POIS", payload: pois });
    }
    if (poiPoints) {
      yield put({ type: "SET_POI_POINTS", payload: poiPoints });
    }
    if (groups) {
      yield put({ type: "SET_GROUPS", payload: groups });
    }

    if (ranches) {
      yield put({ type: "SET_RANCHES", payload: ranches });
    }
    if (attachments) {
      yield put({ type: "SET_ATTACHMENTS", payload: attachments });
    }
    if (animalAttachments) {
      yield put({ type: "SET_ANIMAL_ATTACHMENTS", payload: animalAttachments });
    }
    if (animalCalvings) {
      yield put({ type: "SET_ANIMAL_CALVINGS", payload: animalCalvings });
    }
    if (logs) {
      console.log("setLogs from non error side");
      yield put({ type: "SET_LOGS", payload: logs });
    }

    if (animalLogs) {
      yield put({ type: "SET_ANIMAL_LOGS", payload: animalLogs });
    }
    if (tags) {
      yield put({ type: "SET_TAGS", payload: tags });
    }
    if (comments) {
      yield put({ type: "SET_COMMENTS", payload: comments });
    }
    if (animalComments) {
      yield put({ type: "SET_ANIMAL_COMMENTS", payload: animalComments });
    }
    console.log("updating lastSync:", currentSyncDate);
    db.defaults.put({ name: "lastsync", value: currentSyncDate });

    yield signalRService.shouldConnect();
    yield put({ type: "RESET_LOADING" });

    //signalRService.joinGroup(company);
  } catch (error) {
    let animals = yield db.animals.toArray();
    let companies = yield db.companies.toArray();
    let fences = yield db.fences.toArray();
    let fencePoints = yield db.fencePoints.toArray();
    let pois = yield db.pois.toArray();
    let poiPoints = yield db.poiPoints.toArray();
    let groups = yield db.groups.toArray();
    let ranches = yield db.ranches.toArray();
    let attachments = yield db.attachments.toArray();
    let animalAttachments = yield db.animalAttachments.toArray();
    let animalCalvings = yield db.animalCalvings.toArray();
    let animalLogs = yield db.animalLogs.toArray();
    let logs = yield db.logs.toArray();
    let tags = yield db.tags.toArray();
    let comments = yield db.comments.toArray();
    let animalComments = yield db.animalComments.toArray();
    let companyUser = yield db.companyUser.toArray();
    let userDefaults = companyUser[0];

    yield db.companyUser.put(userDefaults);
    yield put({
      type: "SET_DEFAULT",
      payload: {
        breed: userDefaults.defaultBreed,
        breedDisplay: userDefaults.defaultBreedDisplay,
        fence: userDefaults.defaultFence,
        group: userDefaults.defaultGroup,
        ranch: userDefaults.defaultRanch,
      },
    });

    yield put({ type: "SET_ANIMALS", payload: animals });
    yield put({ type: "SET_COMPANIES", payload: companies });
    yield put({ type: "SET_FENCES", payload: fences });
    yield put({ type: "SET_FENCE_POINTS", payload: fencePoints });
    yield put({ type: "SET_POIS", payload: pois });
    yield put({ type: "SET_POI_POINTS", payload: poiPoints });
    yield put({ type: "SET_GROUPS", payload: groups });
    yield put({ type: "SET_RANCHES", payload: ranches });
    yield put({ type: "SET_ATTACHMENTS", payload: attachments });
    yield put({ type: "SET_ANIMAL_ATTACHMENTS", payload: animalAttachments });
    yield put({ type: "SET_ANIMAL_CALVINGS", payload: animalCalvings });
    yield put({ type: "SET_LOGS", payload: logs });
    yield put({ type: "SET_ANIMAL_LOGS", payload: animalLogs });
    yield put({ type: "SET_TAGS", payload: tags });
    yield put({ type: "SET_COMMENTS", payload: comments });
    yield put({ type: "SET_ANIMAL_COMMENTS", payload: animalComments });
    yield put({ type: "RESET_LOADING" });

    console.log("syncUserData Failed return data from DB", error);
  }
}

function* syncSaga() {
  yield takeLatest("FULL_PULL_BY_USER", fullPullByUser);
  yield takeLatest("SYNC_USER", syncUserData);
  yield takeLatest("CHANGE_COMPANIES", fullPullByCompany);
}

export default syncSaga;
