import { takeEvery, put } from "redux-saga/effects";
import commentService from "../../Services/commentService";

function* insertCommentSaga(action) {
  try {
    const response = yield commentService.insert(action.payload);
    yield put({ type: "INSERT_COMMENT_REDUCER", payload: response });
  } catch (err) {
    console.log("error inserting comment", err);
  }
}

function* updateCommentSaga(action) {
  try {
    const response = yield commentService.update(action.payload);
    yield put({ type: "UPDATE_COMMENT_REDUCER", payload: response });
  } catch (err) {
    console.log("error updating comment", err);
  }
}

function* commentsSaga() {
  yield takeEvery("INSERT_COMMENT", insertCommentSaga);
  yield takeEvery("UPDATE_COMMENT", updateCommentSaga);
}

export default commentsSaga;
