const animalCommentReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_ANIMAL_COMMENTS": {
      return action.payload;
    }
    case "MASS_INSERT_ANIMAL_COMMENTS_REDUCER": {
      return [...state, ...action.payload];
    }
    case "UPDATE_ANIMAL_COMMENT_REDUCER": {
      let updatedState = state.map((item) => (item.id === action.payload.id ? action.payload : item));
      return updatedState;
    }
    case "INSERT_ANIMAL_COMMENT_REDUCER": {
      return [...state, action.payload];
    }
    case "DELETE_ANIMAL_COMMENT_REDUCER": {
      let items = action.payload;
      let newState = [...state];
      return newState.filter((item) => items.indexOf(item.id) === -1);
    }
    default:
      return state;
  }
};

export default animalCommentReducer;
