import { takeEvery, put } from "redux-saga/effects";
import animalAttachmentService from "../../Services/animalAttachmentService";

function* massInsertAnimalAttachmentSaga(action) {
  try {
    let insertedAnimalAttachments = [];
    for (let animalAttachment of action.payload) {
      const inserted = yield animalAttachmentService.insert(animalAttachment);
      insertedAnimalAttachments.push(inserted);
    }
    yield put({ type: "MASS_INSERT_ANIMAL_ATTACHMENT_REDUCER", payload: insertedAnimalAttachments });
  } catch (err) {
    console.log("error inserting animalAttachment", err);
  }
}

function* massUpdateAnimalAttachmentSaga(action) {
  try {
    for (let animalAttachment of action.payload) {
      const updated = yield animalAttachmentService.update(animalAttachment);
      yield put({ type: "UPDATE_ANIMAL_ATTACHMENT_REDUCER", payload: updated });
    }
  } catch (err) {
    console.log("error inserting animalAttachment", err);
  }
}

function* animalAttachmentSaga() {
  yield takeEvery("MASS_INSERT_ANIMAL_ATTACHMENT", massInsertAnimalAttachmentSaga);
  yield takeEvery("MASS_UPDATE_ANIMAL_ATTACHMENT", massUpdateAnimalAttachmentSaga);
}

export default animalAttachmentSaga;
