const MeasurementInfo = (props) => {
  return (
    <div
      id="measurementInfoOuter"
      className="measurementInfo"
      style={{ position: "absolute", bottom: "50px", left: "0px", zIndex: "1000", display: "none" }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="button"
          onClick={() => window.setDrawingMode("draw-line")}
          data-mode="draw-line"
          aria-label="draw-line"
          title="draw-line"
          className="azure-maps-drawtoolbar-button draw-line"
          style={{ display: "inline-block", margin: "0px" }}
        ></button>
        <button
          type="button"
          onClick={() => window.setDrawingMode("draw-polygon")}
          data-mode="draw-polygon"
          aria-label="draw-polygon"
          title="draw-polygon"
          className="azure-maps-drawtoolbar-button draw-polygon"
          style={{ display: "inline-block", margin: "0px" }}
        ></button>
        <button
          type="button"
          onClick={() => window.setDrawingMode("draw-rectangle")}
          data-mode="draw-rectangle"
          aria-label="draw-rectangle"
          title="draw-rectangle"
          className="azure-maps-drawtoolbar-button draw-rectangle"
          style={{ display: "inline-block", margin: "0px" }}
        ></button>
        <button
          type="button"
          onClick={() => window.setDrawingMode("draw-circle")}
          data-mode="draw-circle"
          aria-label="draw-circle"
          title="draw-circle"
          className="azure-maps-drawtoolbar-button draw-circle"
          style={{ display: "inline-block", margin: "0px" }}
        ></button>
      </div>
      <div id="measurementInfo"></div>
    </div>
  );
};

export default MeasurementInfo;
