import { takeEvery, put } from "redux-saga/effects";
import tagService from "../../Services/tagService";

function* insertTag(action) {
  try {
    const inserted = yield tagService.insert(action.payload);

    yield put({ type: "INSERT_TAG_REDUCER", payload: inserted });
  } catch (err) {
    console.log("error mass inserting animal logs", err);
  }
}

function* updateTag(action) {
  try {
    const updated = yield tagService.update(action.payload);
    yield put({ type: "UPDATE_TAG_REDUCER", payload: updated });
  } catch (err) {
    console.log("error mass inserting animal logs", err);
  }
}

function* tagSaga() {
  yield takeEvery("INSERT_TAG", insertTag);
  yield takeEvery("UPDATE_TAG", updateTag);
}

export default tagSaga;
