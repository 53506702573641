import { takeEvery, put } from "redux-saga/effects";
import groupService from "../../Services/groupService";

function* insertGroupSaga(action) {
  try {
    const response = yield groupService.insert(action.payload);
    yield put({ type: "INSERT_GROUP_REDUCER", payload: response });
  } catch (err) {
    console.log("error inserting group", err);
  }
}

function* updateGroupSaga(action) {
  try {
    const response = yield groupService.update(action.payload);
    yield put({ type: "UPDATE_GROUP_REDUCER", payload: response });
  } catch (err) {
    console.log("error updating group", err);
  }
}
function* deleteGroupSaga(action) {
  try {
    yield groupService.deleteFromServer(action.payload);
    yield put({ type: "DELETE_GROUP_REDUCER", payload: [action.payload.id] });
  } catch (err) {
    console.log("error deleting group", err);
  }
}

function* groupsSaga() {
  yield takeEvery("INSERT_GROUP", insertGroupSaga);
  yield takeEvery("UPDATE_GROUP", updateGroupSaga);
  yield takeEvery("DELETE_GROUP", deleteGroupSaga);
}

export default groupsSaga;
