import axios from "axios";
import dayjs from "dayjs";
import { db } from "../indexeddb/indexeddb";
import { baseUrl, config } from "../appSettings";
import { store } from "../index";
import dateTimeModule from "../Modules/dateTime";

const url1 = "CellStrength";
const view = "GetAllByFilter?";
const reporting = "GetAllReportingTagDataByFilter?";
const tracking = "GetAllTrackingTagDataByFilter?";
const signal = "GetAllSignalTagDataByFilter?";

async function getAllByFilter(cellId) {
  let params = [];

  let company = await db.defaults.get("defaultCompany");
  //params.push(`CompanyId=${company.value}`);
  if (cellId) {
    params.push(`CellId=${cellId}`);
  }
  let paramString = params.join("&");
  return axios.get(`${baseUrl}/${url1}/${view}${paramString}`, config);
}
function getAll() {
  return axios.get(`${baseUrl}/${url1}`, config);
}

function insert(data) {
  data.createdByDate = dateTimeModule.currentUtc();
  return axios.post(`${baseUrl}/${url1}`, data, config);
}

function update(data) {
  data.lastUpdatedByDate = dateTimeModule.currentUtc();
  return axios.put(`${baseUrl}/${url1}/${data.id}`, data, config);
}

function _delete(data) {
  data.inActiveDate = dateTimeModule.currentUtc();
  return axios.put(`${baseUrl}/${url1}/${data.id}`, data, config);
}

const cellStrengthService = {
  getAllByFilter,
  insert,
  update,
  _delete,
  getAll,
};
export default cellStrengthService;
