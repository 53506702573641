import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dateTimeModule from "../../../Modules/dateTime";

const LocationTool = (props) => {
  const [locationToolFromDateTime, setTagReportFromDateTime] = useState(dateTimeModule.currentUtcMinus(1));
  const [locationToolToDateTime, setTagReportToDateTime] = useState(dateTimeModule.currentUtc());
  const [tagReportSampleSize, setTagReportSampleSize] = useState(60);

  return (
    <div className="d-none">
      <div
        style={{
          position: "absolute",
          top: "90px",
          left: "10px",
          padding: "10px",
          backgroundColor: "white",
          borderRadius: "10px",
          zIndex: "1002",
          display: "none",
        }}
      >
        {/* <input type="button" value="Show/Hide" onClick={window.ShowHideLocationLayer} /> */}
        <div id="controlLocationPanel">
          {/* <br />
        <input type="button" value="Play" onClick={window.PlayLocationLayer} />
        <input type="button" value="Pause" onClick={window.PauseLocationLayer} />
        <input type="button" value="Refresh" onClick={window.ReBuildLocationLayer} />
        <br />
        <input type="button" value="<< Day" onClick={() => window.ManualLocationLayer(-24)} />
        <input type="button" value="<< Hour" onClick={() => window.ManualLocationLayer(-1)} />
        <input type="button" value=">> Hour" onClick={() => window.ManualLocationLayer(1)} />
        <input type="button" value=">> Day" onClick={() => window.ManualLocationLayer(24)} />
        <br /> */}

          <div className="row d-none">
            <div id="locationToolFromDateEditContainer" className="col-md-6 newcard" style={{ display: "block" }}>
              <label htmlFor="toReportFromDate">From Date/Time:</label>
              <br />
              <input
                type="datetime-local"
                value={dateTimeModule.dateTimePickerFormat(locationToolFromDateTime)}
                onChange={(e) => setTagReportFromDateTime(dateTimeModule.saveTimeStamp(e.target.value))}
                id="newlocationToolFromDate"
                className="w-100"
              />
            </div>
            <div id="locationToolToDateEditContainer" className="col-md-6 newcard" style={{ display: "block" }}>
              <label htmlFor="locationToolToDate">To Date/Time:</label>
              <br />
              <input
                type="datetime-local"
                value={dateTimeModule.dateTimePickerFormat(locationToolToDateTime)}
                onChange={(e) => setTagReportToDateTime(dateTimeModule.saveTimeStamp(e.target.value))}
                id="newlocationToolToDate"
                className="w-100"
              />
            </div>
            <div className="col-md-4 newcard" style={{ display: "block" }}>
              <label htmlFor="toReportSampleSize">Sample Size:</label>
              <br />
              <select
                id="myLocationSampleSize"
                onChange={(e) => setTagReportSampleSize(e.target.value)}
                value={tagReportSampleSize}
              >
                <option value="1">1 Min.</option>
                <option value="5">5 Min.</option>
                <option value="15">15 Min.</option>
                <option value="30">30 Min.</option>
                <option value="60">60 Min.</option>
              </select>
            </div>
          </div>
          {props.timeStamp && dateTimeModule.displayTimeStamp(props.timeStamp)}
        </div>
        <div
          id="messageLocationPanel"
          style={{
            backgroundColor: "white",
            padding: "2px",
            borderRadius: "15px",
            width: "200px",
            textAlign: "center",
            zIndex: "1002",
            display: "none",
          }}
        ></div>
      </div>
    </div>
  );
};

export default LocationTool;
