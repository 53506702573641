import Oidc from "oidc-client";

const configDeploy = {
  response_mode: "query",
  authority: "https://login.701x.com/",
  client_id: "LivestockAI",
  redirect_uri: "https://mobile.701x.com/#/signin-oidc/",
  scope: "openid profile email offline_access",
  post_logout_redirect_uri: "https://mobile.701x.com/",
  response_type: "code",
  allow_offline_access: true,
  max_age: 60 * 60 * 24 * 10 * 1000,
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
};
const configDeployAzureUrl = {
  response_mode: "query",
  authority: "https://login.701x.com/",
  client_id: "LivestockAI",
  redirect_uri: "https://white-hill-00b437010.azurestaticapps.net/#/signin-oidc/",
  scope: "openid profile email offline_access",
  post_logout_redirect_uri: "https://white-hill-00b437010.azurestaticapps.net/",
  response_type: "code",
  allow_offline_access: true,
  max_age: 60 * 60 * 24 * 10 * 1000,
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
};
const configWeb = {
  response_mode: "query",
  authority: "https://login.701x.com/",
  client_id: "LivestockAI",
  redirect_uri: "http://localhost:3000/#/signin-oidc/",
  scope: "openid profile email offline_access",
  post_logout_redirect_uri: "http://localhost:3000/",
  response_type: "code",
  allow_offline_access: true,
  max_age: 60 * 60 * 24 * 10 * 1000,
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
};
const configMobile = {
  response_mode: "query",
  authority: "https://login.701x.com/",
  client_id: "LivestockAI",
  redirect_uri: "capacitor://localhost/#/signin-oidc/",
  scope: "openid profile email offline_access",
  post_logout_redirect_uri: "capacitor://localhost/",
  response_type: "code",
  allow_offline_access: true,
  max_age: 60 * 60 * 24 * 10 * 1000,
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
};

export const authMgr = new Oidc.UserManager(configDeploy);

//"http://localhost:3000/#/signin-oidc/"
//"capacitor://localhost/#/signin-oidc/";
