import { useSelector } from "react-redux";

const sortDescription = (a, b) => {
  if (a.description < b.description) {
    return -1;
  }
  if (a.description > b.description) {
    return 1;
  }
  return 0;
};

export const useCompanies = () => {
  return useSelector((state) => {
    let allState = state.companies;
    return allState.sort(sortDescription);
  });
};
export const useRanches = () => {
  return useSelector((state) => {
    let allState = state.ranches;
    return allState.sort(sortDescription);
  });
};
export const useGroups = () => {
  return useSelector((state) => {
    let allState = state.groups;
    return allState.sort(sortDescription);
  });
};
export const useFences = () => {
  return useSelector((state) => {
    let allState = state.fences;
    return allState.sort(sortDescription);
  });
};
export const usePOIs = () => {
  return useSelector((state) => {
    let allState = state.pois;
    return allState.sort(sortDescription);
  });
};
export const useUser = () => {
  return useSelector((state) => state.user);
};
export const useDefaults = () => {
  return useSelector((state) => state.defaults);
};
export const useTags = (id) => {
  return useSelector((state) => state.tags);
};
