import { v4 as uuid } from "uuid";
import tagDataService from "./Services/tagDataService";

// [
//   {
//     id: "1",
//     companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     farmId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     groupId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     farmVirtualFenceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     tagId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     maleAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     femaleAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     fosterAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     sires: "string",
//     type: "COW",
//     code: "string",
//     description: "string",
//     cattleNumber: "1",
//     usdaNumber: "string",
//     registrationNumber: "string",
//     sex: "string",
//     dob: "2021-09-24T18:18:06.894Z",
//     age: "string",
//     isCalve: true,
//     breed: "string",
//     breedDisplay: "string",
//     longitude: -96.828817,
//     latitude: 46.722681,
//     altitude: 0,
//     position_Uncertainty: 0,
//     heading: 0,
//     temperature: 0,
//     pressure: 0,
//     cell_RSSI: 0,
//     gpS_TTFF: 0,
//     eventTypeId: "string",
//     eventStatusId: "string",
//     isFreemartin: true,
//     isCrastrated: true,
//     crastrationMethod: "string",
//     doNotBreedFlag: true,
//     lastCheckInDate: null,
//     lastUploadedDate: null,
//     createdByDate: null,
//     createdByUserId: null,
//     lastUpdatedByDate: null,
//     lastUpdatedByUserId: null,
//     inActiveDate: null,
//     purchase_Date: null,
//     enteredFarm_Date: null,
//     sold_Date: null,
//     lost_Date: null,
//     stolen_Date: null,
//     death_Date: null,
//     cull_Date: null,
//     planToKeep_Date: null,
//     purchase_Price: 0,
//     sold_Price: 0,
//     weight: 0,
//     healthScale: 0,
//     heatScale: 0,
//     closeToCalvingPercentage: 0,
//     estimatedDateInseminated: "2021-09-24T18:18:06.894Z",
//     actualDateInseminated: "2021-09-24T18:18:06.894Z",
//     estimatedDateCalved: "2021-09-24T18:18:06.894Z",
//     actualDateCalved: "2021-09-24T18:18:06.894Z",
//     hasCalved: true,
//     hasAborted: true,
//     hasStillBorn: true,
//     isOpen: true,
//     farmPointOfInterestId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     outOfFence: true,
//     maleCattleNumber: "string",
//     femaleCattleNumber: "string",
//     maleRegistrationNumber: "string",
//     femaleRegistrationNumber: "string",
//     calving_Ease_Direct: 0,
//     birth_Weight: 0,
//     weaning_Weight_Direct: 0,
//     yearling_Weight: 0,
//     yearling_Height: 0,
//     milk: 0,
//     maternal_Weaning_Weight: 0,
//     gestation_Length: 0,
//     calving_Ease_Maternal: 0,
//     mature_Daughter_Height: 0,
//     mature_Daughter_Weight: 0,
//     scrotal_Circumference: 0,
//     heifer_Pregnancy: 0,
//     udder: 0,
//     teat: 0,
//     carcass_Weight: 0,
//     percent_Retail_Cuts: 0,
//     marbling: 0,
//     imf: 0,
//     rib_Eye_Area: 0,
//     fat_Thickness: 0,
//     rump_Fat_Thickness: 0,
//     tenderness: 0,
//     days_To_Finish: 0,
//     residual_Average_Daily_Gain: 0,
//     residual_Feed_Intake: 0,
//     dry_Matter_Intake: 0,
//     stayability: 0,
//     maintenance_Energy: 0,
//     docility: 0,
//     companyDescription: "string",
//     farmDescription: "string",
//     groupDescription: "string",
//     farmVirtualFenceDescription: "string",
//     farmPointOfInterestDescription: "string",
//     tagDescription: "string",
//     networkNumber: "string",
//     maleCode: "string",
//     maleDescription: "string",
//     maleTagDescription: "string",
//     maleNetworkNumber: "string",
//     femaleCode: "string",
//     femaleDescription: "string",
//     femaleTagDescription: "string",
//     femaleNetworkNumber: "string",
//     fosterCode: "string",
//     fosterDescription: "string",
//     fosterTagDescription: "string",
//     fosterNetworkNumber: "string",
//     fosterCattleNumber: "string",
//     isNew: true,
//     isUpdate: true,
//     isDelete: true,
//   },
//   {
//     id: "2",
//     companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     farmId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     groupId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     farmVirtualFenceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     tagId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     maleAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     femaleAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     fosterAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     sires: "string",
//     type: "COW",
//     code: "string",
//     description: "string",
//     cattleNumber: "2",
//     usdaNumber: "string",
//     registrationNumber: "string",
//     sex: "string",
//     dob: "2021-09-24T18:18:06.894Z",
//     age: "string",
//     isCalve: true,
//     breed: "string",
//     breedDisplay: "string",
//     longitude: -96.82882,
//     latitude: 46.722683,
//     altitude: 0,
//     position_Uncertainty: 0,
//     heading: 0,
//     temperature: 0,
//     pressure: 0,
//     cell_RSSI: 0,
//     gpS_TTFF: 0,
//     eventTypeId: "string",
//     eventStatusId: "string",
//     isFreemartin: true,
//     isCrastrated: true,
//     crastrationMethod: "string",
//     doNotBreedFlag: true,
//     lastCheckInDate: null,
//     lastUploadedDate: null,
//     createdByDate: null,
//     createdByUserId: null,
//     lastUpdatedByDate: null,
//     lastUpdatedByUserId: null,
//     inActiveDate: null,
//     purchase_Date: null,
//     enteredFarm_Date: null,
//     sold_Date: null,
//     lost_Date: null,
//     stolen_Date: null,
//     death_Date: null,
//     cull_Date: null,
//     planToKeep_Date: null,
//     purchase_Price: 0,
//     sold_Price: 0,
//     weight: 0,
//     healthScale: 0,
//     heatScale: 0,
//     closeToCalvingPercentage: 0,
//     estimatedDateInseminated: "2021-09-24T18:18:06.894Z",
//     actualDateInseminated: "2021-09-24T18:18:06.894Z",
//     estimatedDateCalved: "2021-09-24T18:18:06.894Z",
//     actualDateCalved: "2021-09-24T18:18:06.894Z",
//     hasCalved: true,
//     hasAborted: true,
//     hasStillBorn: true,
//     isOpen: true,
//     farmPointOfInterestId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     outOfFence: true,
//     maleCattleNumber: "string",
//     femaleCattleNumber: "string",
//     maleRegistrationNumber: "string",
//     femaleRegistrationNumber: "string",
//     calving_Ease_Direct: 0,
//     birth_Weight: 0,
//     weaning_Weight_Direct: 0,
//     yearling_Weight: 0,
//     yearling_Height: 0,
//     milk: 0,
//     maternal_Weaning_Weight: 0,
//     gestation_Length: 0,
//     calving_Ease_Maternal: 0,
//     mature_Daughter_Height: 0,
//     mature_Daughter_Weight: 0,
//     scrotal_Circumference: 0,
//     heifer_Pregnancy: 0,
//     udder: 0,
//     teat: 0,
//     carcass_Weight: 0,
//     percent_Retail_Cuts: 0,
//     marbling: 0,
//     imf: 0,
//     rib_Eye_Area: 0,
//     fat_Thickness: 0,
//     rump_Fat_Thickness: 0,
//     tenderness: 0,
//     days_To_Finish: 0,
//     residual_Average_Daily_Gain: 0,
//     residual_Feed_Intake: 0,
//     dry_Matter_Intake: 0,
//     stayability: 0,
//     maintenance_Energy: 0,
//     docility: 0,
//     companyDescription: "string",
//     farmDescription: "string",
//     groupDescription: "string",
//     farmVirtualFenceDescription: "string",
//     farmPointOfInterestDescription: "string",
//     tagDescription: "string",
//     networkNumber: "string",
//     maleCode: "string",
//     maleDescription: "string",
//     maleTagDescription: "string",
//     maleNetworkNumber: "string",
//     femaleCode: "string",
//     femaleDescription: "string",
//     femaleTagDescription: "string",
//     femaleNetworkNumber: "string",
//     fosterCode: "string",
//     fosterDescription: "string",
//     fosterTagDescription: "string",
//     fosterNetworkNumber: "string",
//     fosterCattleNumber: "string",
//     isNew: true,
//     isUpdate: true,
//     isDelete: true,
//   },
//   {
//     id: "3",
//     companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     farmId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     groupId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     farmVirtualFenceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     tagId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     maleAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     femaleAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     fosterAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     sires: "string",
//     type: "COW",
//     code: "string",
//     description: "string",
//     cattleNumber: "3",
//     usdaNumber: "string",
//     registrationNumber: "string",
//     sex: "string",
//     dob: "2021-09-24T18:18:06.894Z",
//     age: "string",
//     isCalve: true,
//     breed: "string",
//     breedDisplay: "string",
//     longitude: -96.828815,
//     latitude: 46.722673,
//     altitude: 0,
//     position_Uncertainty: 0,
//     heading: 0,
//     temperature: 0,
//     pressure: 0,
//     cell_RSSI: 0,
//     gpS_TTFF: 0,
//     eventTypeId: "string",
//     eventStatusId: "string",
//     isFreemartin: true,
//     isCrastrated: true,
//     crastrationMethod: "string",
//     doNotBreedFlag: true,
//     lastCheckInDate: null,
//     lastUploadedDate: null,
//     createdByDate: null,
//     createdByUserId: null,
//     lastUpdatedByDate: null,
//     lastUpdatedByUserId: null,
//     inActiveDate: null,
//     purchase_Date: null,
//     enteredFarm_Date: null,
//     sold_Date: null,
//     lost_Date: null,
//     stolen_Date: null,
//     death_Date: null,
//     cull_Date: null,
//     planToKeep_Date: null,
//     purchase_Price: 0,
//     sold_Price: 0,
//     weight: 0,
//     healthScale: 0,
//     heatScale: 0,
//     closeToCalvingPercentage: 0,
//     estimatedDateInseminated: "2021-09-24T18:18:06.894Z",
//     actualDateInseminated: "2021-09-24T18:18:06.894Z",
//     estimatedDateCalved: "2021-09-24T18:18:06.894Z",
//     actualDateCalved: "2021-09-24T18:18:06.894Z",
//     hasCalved: true,
//     hasAborted: true,
//     hasStillBorn: true,
//     isOpen: true,
//     farmPointOfInterestId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     outOfFence: true,
//     maleCattleNumber: "string",
//     femaleCattleNumber: "string",
//     maleRegistrationNumber: "string",
//     femaleRegistrationNumber: "string",
//     calving_Ease_Direct: 0,
//     birth_Weight: 0,
//     weaning_Weight_Direct: 0,
//     yearling_Weight: 0,
//     yearling_Height: 0,
//     milk: 0,
//     maternal_Weaning_Weight: 0,
//     gestation_Length: 0,
//     calving_Ease_Maternal: 0,
//     mature_Daughter_Height: 0,
//     mature_Daughter_Weight: 0,
//     scrotal_Circumference: 0,
//     heifer_Pregnancy: 0,
//     udder: 0,
//     teat: 0,
//     carcass_Weight: 0,
//     percent_Retail_Cuts: 0,
//     marbling: 0,
//     imf: 0,
//     rib_Eye_Area: 0,
//     fat_Thickness: 0,
//     rump_Fat_Thickness: 0,
//     tenderness: 0,
//     days_To_Finish: 0,
//     residual_Average_Daily_Gain: 0,
//     residual_Feed_Intake: 0,
//     dry_Matter_Intake: 0,
//     stayability: 0,
//     maintenance_Energy: 0,
//     docility: 0,
//     companyDescription: "string",
//     farmDescription: "string",
//     groupDescription: "string",
//     farmVirtualFenceDescription: "string",
//     farmPointOfInterestDescription: "string",
//     tagDescription: "string",
//     networkNumber: "string",
//     maleCode: "string",
//     maleDescription: "string",
//     maleTagDescription: "string",
//     maleNetworkNumber: "string",
//     femaleCode: "string",
//     femaleDescription: "string",
//     femaleTagDescription: "string",
//     femaleNetworkNumber: "string",
//     fosterCode: "string",
//     fosterDescription: "string",
//     fosterTagDescription: "string",
//     fosterNetworkNumber: "string",
//     fosterCattleNumber: "string",
//     isNew: true,
//     isUpdate: true,
//     isDelete: true,
//   },
//   {
//     id: "4",
//     companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     farmId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     groupId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     farmVirtualFenceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     tagId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     maleAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     femaleAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     fosterAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     sires: "string",
//     type: "COW",
//     code: "string",
//     description: "string",
//     cattleNumber: "4",
//     usdaNumber: "string",
//     registrationNumber: "string",
//     sex: "string",
//     dob: "2021-09-24T18:18:06.894Z",
//     age: "string",
//     isCalve: true,
//     breed: "string",
//     breedDisplay: "string",
//     longitude: -96.828815,
//     latitude: 46.722681,
//     altitude: 0,
//     position_Uncertainty: 0,
//     heading: 0,
//     temperature: 0,
//     pressure: 0,
//     cell_RSSI: 0,
//     gpS_TTFF: 0,
//     eventTypeId: "string",
//     eventStatusId: "string",
//     isFreemartin: true,
//     isCrastrated: true,
//     crastrationMethod: "string",
//     doNotBreedFlag: true,
//     lastCheckInDate: null,
//     lastUploadedDate: null,
//     createdByDate: null,
//     createdByUserId: null,
//     lastUpdatedByDate: null,
//     lastUpdatedByUserId: null,
//     inActiveDate: null,
//     purchase_Date: null,
//     enteredFarm_Date: null,
//     sold_Date: null,
//     lost_Date: null,
//     stolen_Date: null,
//     death_Date: null,
//     cull_Date: null,
//     planToKeep_Date: null,
//     purchase_Price: 0,
//     sold_Price: 0,
//     weight: 0,
//     healthScale: 0,
//     heatScale: 0,
//     closeToCalvingPercentage: 0,
//     estimatedDateInseminated: "2021-09-24T18:18:06.894Z",
//     actualDateInseminated: "2021-09-24T18:18:06.894Z",
//     estimatedDateCalved: "2021-09-24T18:18:06.894Z",
//     actualDateCalved: "2021-09-24T18:18:06.894Z",
//     hasCalved: true,
//     hasAborted: true,
//     hasStillBorn: true,
//     isOpen: true,
//     farmPointOfInterestId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
//     outOfFence: true,
//     maleCattleNumber: "string",
//     femaleCattleNumber: "string",
//     maleRegistrationNumber: "string",
//     femaleRegistrationNumber: "string",
//     calving_Ease_Direct: 0,
//     birth_Weight: 0,
//     weaning_Weight_Direct: 0,
//     yearling_Weight: 0,
//     yearling_Height: 0,
//     milk: 0,
//     maternal_Weaning_Weight: 0,
//     gestation_Length: 0,
//     calving_Ease_Maternal: 0,
//     mature_Daughter_Height: 0,
//     mature_Daughter_Weight: 0,
//     scrotal_Circumference: 0,
//     heifer_Pregnancy: 0,
//     udder: 0,
//     teat: 0,
//     carcass_Weight: 0,
//     percent_Retail_Cuts: 0,
//     marbling: 0,
//     imf: 0,
//     rib_Eye_Area: 0,
//     fat_Thickness: 0,
//     rump_Fat_Thickness: 0,
//     tenderness: 0,
//     days_To_Finish: 0,
//     residual_Average_Daily_Gain: 0,
//     residual_Feed_Intake: 0,
//     dry_Matter_Intake: 0,
//     stayability: 0,
//     maintenance_Energy: 0,
//     docility: 0,
//     companyDescription: "string",
//     farmDescription: "string",
//     groupDescription: "string",
//     farmVirtualFenceDescription: "string",
//     farmPointOfInterestDescription: "string",
//     tagDescription: "string",
//     networkNumber: "string",
//     maleCode: "string",
//     maleDescription: "string",
//     maleTagDescription: "string",
//     maleNetworkNumber: "string",
//     femaleCode: "string",
//     femaleDescription: "string",
//     femaleTagDescription: "string",
//     femaleNetworkNumber: "string",
//     fosterCode: "string",
//     fosterDescription: "string",
//     fosterTagDescription: "string",
//     fosterNetworkNumber: "string",
//     fosterCattleNumber: "string",
//     isNew: true,
//     isUpdate: true,
//     isDelete: true,
//   },
// ];

const initialAnimal = {
  id: "4",
  companyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  farmId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  groupId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  farmVirtualFenceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  tagId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  maleAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  femaleAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  fosterAnimalId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  sires: "string",
  type: "COW",
  code: "string",
  description: "string",
  cattleNumber: "4",
  usdaNumber: "string",
  registrationNumber: "string",
  sex: "F",
  dob: "2021-09-24T18:18:06.894Z",
  age: `${Math.floor(Math.random() * 10)}`,
  isCalve: true,
  breed: "Angus",
  breedDisplay: "Angus",
  longitude: -96.828815,
  latitude: 46.722681,
  altitude: 0,
  position_Uncertainty: 0,
  heading: 0,
  temperature: 0,
  pressure: 0,
  cell_RSSI: 0,
  gpS_TTFF: 0,
  eventTypeId: "string",
  eventStatusId: "string",
  isFreemartin: true,
  isCrastrated: true,
  crastrationMethod: "string",
  doNotBreedFlag: true,
  lastCheckInDate: null,
  lastUploadedDate: null,
  createdByDate: null,
  createdByUserId: null,
  lastUpdatedByDate: null,
  lastUpdatedByUserId: null,
  inActiveDate: null,
  purchase_Date: null,
  enteredFarm_Date: null,
  sold_Date: null,
  lost_Date: null,
  stolen_Date: null,
  death_Date: null,
  cull_Date: null,
  planToKeep_Date: null,
  purchase_Price: 0,
  sold_Price: 0,
  weight: 0,
  healthScale: 0,
  heatScale: 0,
  closeToCalvingPercentage: 0,
  estimatedDateInseminated: "2021-09-24T18:18:06.894Z",
  actualDateInseminated: "2021-09-24T18:18:06.894Z",
  estimatedDateCalved: "2021-09-24T18:18:06.894Z",
  actualDateCalved: "2021-09-24T18:18:06.894Z",
  hasCalved: true,
  hasAborted: true,
  hasStillBorn: true,
  isOpen: true,
  farmPointOfInterestId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  outOfFence: true,
  maleCattleNumber: "string",
  femaleCattleNumber: "string",
  maleRegistrationNumber: "string",
  femaleRegistrationNumber: "string",
  calving_Ease_Direct: 0,
  birth_Weight: 0,
  weaning_Weight_Direct: 0,
  yearling_Weight: 0,
  yearling_Height: 0,
  milk: 0,
  maternal_Weaning_Weight: 0,
  gestation_Length: 0,
  calving_Ease_Maternal: 0,
  mature_Daughter_Height: 0,
  mature_Daughter_Weight: 0,
  scrotal_Circumference: 0,
  heifer_Pregnancy: 0,
  udder: 0,
  teat: 0,
  carcass_Weight: 0,
  percent_Retail_Cuts: 0,
  marbling: 0,
  imf: 0,
  rib_Eye_Area: 0,
  fat_Thickness: 0,
  rump_Fat_Thickness: 0,
  tenderness: 0,
  days_To_Finish: 0,
  residual_Average_Daily_Gain: 0,
  residual_Feed_Intake: 0,
  dry_Matter_Intake: 0,
  stayability: 0,
  maintenance_Energy: 0,
  docility: 0,
  companyDescription: "string",
  farmDescription: "701Ranch",
  groupDescription: "string",
  farmVirtualFenceDescription: "Grand Farm",
  farmPointOfInterestDescription: "string",
  tagDescription: "string",
  networkNumber: `${Math.floor(Math.random() * 5000)}`,
  maleCode: "string",
  maleDescription: "string",
  maleTagDescription: "string",
  maleNetworkNumber: "string",
  femaleCode: "string",
  femaleDescription: "string",
  femaleTagDescription: "string",
  femaleNetworkNumber: "string",
  fosterCode: "string",
  fosterDescription: "string",
  fosterTagDescription: "string",
  fosterNetworkNumber: "string",
  fosterCattleNumber: "string",
  isNew: true,
  isUpdate: true,
  isDelete: true,
};

function generateRandomAnimalList(numberOfAnimals) {
  let animalList = [];

  for (let i = 0; i < numberOfAnimals; i++) {
    let newAnimal = { ...initialAnimal };
    let point = tagDataService.generateRandomLatLon(
      { from: 46.718379, to: 46.72267 },
      { from: -96.824387, to: -96.828815 },
      6
    );
    newAnimal.id = uuid();
    newAnimal.cattleNumber = i + 1;
    newAnimal.latitude = point.latitude;
    newAnimal.longitude = point.longitude;
    newAnimal.networkNumber = `${Math.floor(Math.random() * 5000)}`;
    newAnimal.age = `${Math.floor(Math.random() * 10)}`;
    animalList.push(newAnimal);
  }
  return animalList;
}

export const animalList = generateRandomAnimalList(4);
