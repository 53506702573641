import axios from "axios";
import { put, takeLatest, takeEvery } from "redux-saga/effects";
import { fetchWrapper } from "workbox-core/_private";
import { baseUrl, config } from "../../appSettings";
import { db } from "../../indexeddb/indexeddb";
import animalService from "../../Services/animalService";

// worker Saga: will be fired on "FETCH_ALL_ANIMALS_BY_USER" actions
//action.payload = user.id
function* fetchAllAnimalsByUser(action) {
  try {
    const response = yield axios.get(`${baseUrl}/Animal/AnimalDataByFilter?UserId=${action.payload}`, config);

    // now that the session has given us a user object
    // with an id and username set the client-side user object to let
    // the client-side code know the user is logged in
    yield db.animals.clear();
    yield db.animals.bulkAdd(response.data);
    yield put({ type: "SET_ANIMALS", payload: response.data });
  } catch (error) {
    console.log("User get request failed", error);
  }
}

//action.payload needs to be entire animal object.
function* updateAnimalSaga(action) {
  try {
    const animal = yield animalService.update(action.payload);

    yield put({ type: "UPDATE_ANIMAL_REDUCER", payload: animal });
  } catch (err) {}
}

//action.payload is entire animal object that is to be added
function* insertAnimalSaga(action) {
  try {
    const animal = yield animalService.insert(action.payload);
    yield put({ type: "MASS_INSERT_ANIMALS_REDUCER", payload: [{ ...animal }] });
  } catch (err) {}
}
function* deleteAnimalSaga(action) {
  try {
    yield animalService.deleteFromServer(action.payload);
    yield put({ type: "MASS_DELETE_ANIMALS_REDUCER", payload: [action.payload.id] });
  } catch (err) {}
}

function* getAllAnimalsFromIndexedDb(action) {
  const response = yield db.animals.toArray();
  yield put({ type: "SET_ANIMALS", payload: response });
}

function* massUpdateSaga(action) {
  try {
    yield put({ type: "SET_LOADING_WITH_PROGRESS", payload: `Updating ${action.payload.length} Animals` });
    console.log("massUpdateSaga()");
    let updatedAnimals = [];
    for (let animal of action.payload) {
      const updated = yield animalService.update(animal);
      updatedAnimals.push(updated);
      yield put({
        type: "SET_PERCENT_LOADING",
        payload: ((updatedAnimals.length / action.payload.length) * 100).toFixed(2),
      });
    }
    yield put({ type: "MASS_UPDATE_ANIMALS_REDUCER", payload: updatedAnimals });
    yield put({ type: "RESET_LOADING" });
  } catch (err) {
    console.log("error in massUpdate animalSaga:", err);
  }
}
function* massInsertAnimalsSaga(action) {
  try {
    console.log("massUpdateSaga()");
    yield put({ type: "SET_LOADING_WITH_PROGRESS", payload: `Creating ${action.payload.length} Animals` });

    let insertedAnimals = [];
    for (let animal of action.payload) {
      const updated = yield animalService.insert(animal);
      insertedAnimals.push(updated);
      yield put({
        type: "SET_PERCENT_LOADING",
        payload: ((insertedAnimals.length / action.payload.length) * 100).toFixed(2),
      });
    }
    yield put({ type: "MASS_INSERT_ANIMALS_REDUCER", payload: insertedAnimals });
    yield put({ type: "RESET_LOADING" });
  } catch (err) {
    console.log("error in massUpdate animalSaga:", err);
  }
}
function* massDeleteAnimalsSaga(action) {
  try {
    let deletedAnimals = [];
    for (let animal of action.payload) {
      const deleted = yield animalService.deleteFromServer(animal);
      deletedAnimals.push(deleted);
    }
    yield put({ type: "MASS_DELETE_ANIMALS_REDUCER", payload: deletedAnimals });
  } catch (err) {
    console.log("error in mass delete animals:", err);
  }
}

function* animalsSaga() {
  yield takeLatest("FETCH_ALL_ANIMALS_BY_USER", fetchAllAnimalsByUser);
  yield takeLatest("GET_ANIMALS_FROM_INDEXED_DB", getAllAnimalsFromIndexedDb);
  yield takeEvery("UPDATE_ANIMAL", updateAnimalSaga);
  yield takeEvery("INSERT_ANIMAL", insertAnimalSaga);
  yield takeEvery("DELETE_ANIMAL", deleteAnimalSaga);
  yield takeEvery("MASS_UPDATE_ANIMALS", massUpdateSaga);
  yield takeEvery("MASS_DELETE_ANIMALS", massDeleteAnimalsSaga);
  yield takeEvery("MASS_INSERT_ANIMALS", massInsertAnimalsSaga);
}

export default animalsSaga;
