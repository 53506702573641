const animalCalvingReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_ANIMAL_CALVINGS": {
      return action.payload;
    }
    case "MASS_INSERT_ANIMAL_CALVING_REDUCER": {
      return [...state, ...action.payload];
    }
    case "UPDATE_ANIMAL_CALVING_REDUCER": {
      let animalCalving = action.payload;
      return state.map((oldAnimalCalving) =>
        oldAnimalCalving.id === animalCalving.id ? animalCalving : oldAnimalCalving
      );
    }
    case "INSERT_ANIMAL_CALVING_REDUCER": {
      return [...state, action.payload];
    }
    case "DELETE_ANIMAL_CALVING_REDUCER": {
      let items = action.payload;
      let newState = [...state];
      return newState.filter((item) => items.indexOf(item.id) === -1);
    }
    default:
      return state;
  }
};

export default animalCalvingReducer;
