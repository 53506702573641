import Dexie from "dexie";

export const db = new Dexie("701x");
db.version(5).stores({
  account: "id,email",
  alerts: "id",
  animalAttachments: "id",
  animalCalvings: "id",
  animalComments: "id",
  animalLogs: "id",
  animalSires: "id",
  animalTags: "id",
  animals: "id",
  attachments: "id",
  chats: "id",
  comments: "id",
  companies: "id",
  companyUser: "id,companyId",
  defaults: "name",
  feedbacks: "id",
  fencePoints: "id",
  fences: "id",
  groups: "id",
  logs: "id",
  poiPoints: "id",
  pois: "id",
  ranches: "id",
  tags: "id",
  tasks: "id",
  users: "id",
});

export const clearAllStores = async () => {
  await db.alerts.clear();
  await db.animalAttachments.clear();
  await db.animalCalvings.clear();
  await db.animalComments.clear();
  await db.animalLogs.clear();
  await db.animalSires.clear();
  await db.animalTags.clear();
  await db.animals.clear();
  await db.attachments.clear();
  await db.chats.clear();
  await db.comments.clear();
  await db.companies.clear();
  await db.defaults.clear();
  await db.feedbacks.clear();
  await db.fencePoints.clear();
  await db.fences.clear();
  await db.groups.clear();
  await db.logs.clear();
  await db.poiPoints.clear();
  await db.pois.clear();
  await db.ranches.clear();
  await db.tags.clear();
  await db.tasks.clear();
  await db.users.clear();
  await db.companyUser.clear();
};
