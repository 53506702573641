import { takeEvery, put } from "redux-saga/effects";
import animalLogService from "../../Services/animalLogService";

function* massInsertAnimalLogs(action) {
  try {
    let insertedAnimalLogs = [];
    for (let animalLog of action.payload) {
      const inserted = yield animalLogService.insert(animalLog);
      insertedAnimalLogs.push(inserted);
    }
    yield put({ type: "MASS_INSERT_ANIMAL_LOG_REDUCER", payload: insertedAnimalLogs });
  } catch (err) {
    console.log("error mass inserting animal logs", err);
  }
}

function* insertAnimalLogs(action) {
  try {
    const inserted = yield animalLogService.insert(action.payload);

    yield put({ type: "INSERT_ANIMAL_LOG_REDUCER", payload: inserted });
  } catch (err) {
    console.log("error inserting animal logs", err);
  }
}

function* deleteAnimalLogs(action) {
  try {
    yield animalLogService.deleteFromServer(action.payload);

    yield put({ type: "DELETE_ANIMAL_LOG_REDUCER", payload: [action.payload.id] });
  } catch (err) {
    console.log("error deleting animal logs", err);
  }
}

function* massUpdateAnimalLogs(action) {
  try {
    for (let animalLog of action.payload) {
      const updated = yield animalLogService.update(animalLog);
      yield put({ type: "UPDATE_ANIMAL_LOG_REDUCER", payload: updated });
    }
  } catch (err) {
    console.log("error mass updating animal logs", err);
  }
}

function* animalLogsSaga() {
  yield takeEvery("MASS_INSERT_ANIMAL_LOG", massInsertAnimalLogs);
  yield takeEvery("INSERT_ANIMAL_LOG", insertAnimalLogs);
  yield takeEvery("MASS_UPDATE_ANIMAL_LOG", massUpdateAnimalLogs);
  yield takeEvery("DELETE_ANIMAL_LOG", deleteAnimalLogs);
}

export default animalLogsSaga;
