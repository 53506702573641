import { animalList } from "../../publicAppSettings";

const animalsReducer = (state = animalList, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default animalsReducer;
