import { takeEvery, put } from "redux-saga/effects";
import poiService from "../../Services/poiService";

function* insertPoiSaga(action) {
  try {
    const response = yield poiService.insert(action.payload);
    yield put({ type: "INSERT_POI_REDUCER", payload: response });
  } catch (err) {
    console.log("error inserting comment", err);
  }
}

function* updatePoiSaga(action) {
  try {
    const response = yield poiService.update(action.payload);
    yield put({ type: "UPDATE_POI_REDUCER", payload: response });
  } catch (err) {
    console.log("error updating comment", err);
  }
}

function* deletePoiSaga(action) {
  try {
    yield poiService.deleteFromServer(action.payload);
    yield put({ type: "DELETE_POI_REDUCER", payload: [action.payload.id] });
  } catch (err) {
    console.log("error updating comment", err);
  }
}

function* poisSaga() {
  yield takeEvery("INSERT_POI", insertPoiSaga);
  yield takeEvery("UPDATE_POI", updatePoiSaga);
  yield takeEvery("DELETE_POI", deletePoiSaga);
}

export default poisSaga;
