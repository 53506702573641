import axios from "axios";
import dayjs from "dayjs";
import { db } from "../indexeddb/indexeddb";
import { baseUrl, config } from "../appSettings";
import { store } from "../index";
import dateTimeModule from "../Modules/dateTime";

const url1 = "AnimalCalving";
const url2 = "AnimalCalvingDataByFilter";
const localDb = db.animalCalvings;

const getAllByUser = (id) => {
  return axios.get(baseUrl + `/${url1}/${url2}?UserId=${id}`, config);
};

const getAllByCompany = (id, company) => {
  return axios.get(`${baseUrl}/${url1}/${url2}?CompanyId=${company}`, config);
};

const sync = async (lastSync, id, company) => {
  //get all Companies from db
  let allFromDb = await localDb.toArray();
  //get current Fence from db

  console.log(`${url1} from localDb`, allFromDb);
  let newFromDb = allFromDb.filter((a) => a.isNew === true);
  let updatedFromDb = allFromDb.filter((a) => a.isUpdate === true && a.isNew === false);
  let deletedFromDb = allFromDb.filter((a) => a.isDelete === true);

  for (let record of newFromDb) {
    insertNewFromSync(record);
  }

  for (let record of updatedFromDb) {
    update(record);
  }

  for (let record of deletedFromDb) {
    deleteFromSync(record);
  }
  let newFromServer = await getNewFromServer(lastSync, id, company);
  for (let data of newFromServer.data) {
    let found = await localDb.get(data.id);
    console.log(`found${url1}:`, found);
    if (found) {
      localDb.put(data);
    } else {
      localDb.add(data);
    }
  }
  let updatedFromServer = await getUpdatedFromServer(lastSync, id, company);
  await localDb.bulkPut(updatedFromServer.data);
  let deletedFromServer = await getDeletedFromServer(lastSync, id, company);
  for (let deleted of deletedFromServer.data) {
    let found = await localDb.get(deleted.id);
    if (found) {
      localDb.delete(deleted.id);
    }
  }
  console.log(`${url1} deleted from server:`, deletedFromServer);
  console.log(`${url1} new from server:`, newFromServer);
  console.log(`${url1} updated from server:`, updatedFromServer);
  return await localDb.toArray();
};

const insertNewFromSync = async (data) => {
  try {
    data.createdByDate = dateTimeModule.currentUtc();
    await axios.post(`${baseUrl}/${url1}`, data, config);
    data.isNew = false;
    localDb.put(data);
  } catch (err) {}
};

const insert = async (data) => {
  try {
    data.createdByDate = dateTimeModule.currentUtc();
    data.isNew = true;
    await localDb.add(data);
    await axios.post(`${baseUrl}/${url1}`, data, config);
    data.isNew = false;
    localDb.put(data);
    return data;
  } catch (err) {
    return data;
  }
};

const update = async (data) => {
  try {
    data.lastUpdatedByDate = dateTimeModule.currentUtc();
    data.isUpdate = true;
    console.log(data);
    localDb.put(data);
    await axios.put(`${baseUrl}/${url1}/${data.id}`, data, config);
    console.log("after axios call");
    data.isUpdate = false;
    localDb.put(data);
    return data;
  } catch (err) {
    return data;
  }
};

const deleteFromSync = async (data) => {
  try {
    data.inActiveDate = dateTimeModule.currentUtc();
    await axios.put(`${baseUrl}/${url1}/${data.id}`, data, config);
    localDb.delete(data.id);
  } catch (err) {}
};

const deleteFromServer = async (data) => {
  try {
    data.inActiveDate = dateTimeModule.currentUtc();
    data.isDelete = true;
    await localDb.put(data);
    await axios.put(`${baseUrl}/${url1}/${data.id}`, data, config);
    localDb.delete(data.id);
  } catch (err) {}
};

const getNewFromServer = async (lastUpdated, user, company) => {
  return axios.get(
    `${baseUrl}/${url1}/${url2}?UserId=${user}&LessThenCreatedByDate=${encodeURIComponent(
      lastUpdated
    )}&CompanyId=${company}`,
    config
  );
};

const getUpdatedFromServer = async (lastUpdated, user, company) => {
  return axios.get(
    `${baseUrl}/${url1}/${url2}?UserId=${user}&LessThenUpdatedByDate=${encodeURIComponent(
      lastUpdated
    )}&CompanyId=${company}`,
    config
  );
};

const getDeletedFromServer = async (lastUpdated, user, company) => {
  return axios.get(
    `${baseUrl}/${url1}/${url2}?UserId=${user}&LessThenDeletedByDate=${encodeURIComponent(
      lastUpdated
    )}&CompanyId=${company}`,
    config
  );
};

const updateFromSignalR = async (data) => {
  let found = await localDb.get(data.id);
  console.log(`found${url1}:`, found);
  if (data.inActiveDate != null) {
    if (found) {
      localDb.delete(data.id);
      store.dispatch({ type: "DELETE_ANIMAL_CALVING_REDUCER", payload: [data.id] });
    }
  } else if (found) {
    localDb.put(data);
    store.dispatch({ type: "UPDATE_ANIMAL_CALVING_REDUCER", payload: data });
  } else {
    localDb.add(data);
    store.dispatch({ type: "INSERT_ANIMAL_CALVING_REDUCER", payload: data });
  }
};

const animalCalvingService = {
  getAllByUser: getAllByUser,
  getAllByCompany: getAllByCompany,
  sync: sync,
  update: update,
  insert: insert,
  deleteFromServer: deleteFromServer,
  updateFromSignalR: updateFromSignalR,
};

export default animalCalvingService;
