const PositionInfo = (props) => {
	return (
		<div
			id="positionInfoOuter"
			className="measurementInfo"
			style={{ position: "absolute", bottom: "50px", right: "0px", zIndex: "1000", display: "none" }}
		>
			<div id="positionInfo"></div>
		</div>
	);
};

export default PositionInfo;
