const defaultFencePoints = [
  {
    id: "139d6d60-b840-4b67-beeb-213ad59e4977",
    companyId: "80b2ebaa-9ddf-48e5-841b-945eb29ad00d",
    farmId: "59eb07d5-c6f0-48c7-b173-d577da690b0f",
    farmVirtualFenceId: "e650eaf0-bdb6-4b7e-b47d-16ce2122ef62",
    type: null,
    longitude: -96.830773,
    latitude: 46.723438,
    altitude: null,
    sequence: 1,
    createdByDate: "2021-09-24T18:12:46+00:00",
    createdByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    lastUpdatedByDate: "2021-09-27T13:47:55+00:00",
    lastUpdatedByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    inActiveDate: null,
    isNew: false,
    isUpdate: false,
    isDelete: false,
  },
  {
    id: "8f9ee28c-64ed-4afe-b805-1ab7cb33b711",
    companyId: "80b2ebaa-9ddf-48e5-841b-945eb29ad00d",
    farmId: "59eb07d5-c6f0-48c7-b173-d577da690b0f",
    farmVirtualFenceId: "e650eaf0-bdb6-4b7e-b47d-16ce2122ef62",
    type: null,
    longitude: -96.823947,
    latitude: 46.723552,
    altitude: null,
    sequence: 2,
    createdByDate: "2021-09-24T18:12:46+00:00",
    createdByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    lastUpdatedByDate: "2021-09-27T13:47:55+00:00",
    lastUpdatedByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    inActiveDate: null,
    isNew: false,
    isUpdate: false,
    isDelete: false,
  },
  {
    id: "99c7e415-95a3-43b2-a507-3445c5217d4c",
    companyId: "80b2ebaa-9ddf-48e5-841b-945eb29ad00d",
    farmId: "59eb07d5-c6f0-48c7-b173-d577da690b0f",
    farmVirtualFenceId: "e650eaf0-bdb6-4b7e-b47d-16ce2122ef62",
    type: null,
    longitude: -96.823897,
    latitude: 46.718345,
    altitude: null,
    sequence: 3,
    createdByDate: "2021-09-24T18:12:46+00:00",
    createdByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    lastUpdatedByDate: "2021-09-27T13:51:39+00:00",
    lastUpdatedByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    inActiveDate: null,
    isNew: false,
    isUpdate: false,
    isDelete: false,
  },
  {
    id: "4c92d765-43b3-46b8-83ee-592c912a672e",
    companyId: "80b2ebaa-9ddf-48e5-841b-945eb29ad00d",
    farmId: "59eb07d5-c6f0-48c7-b173-d577da690b0f",
    farmVirtualFenceId: "e650eaf0-bdb6-4b7e-b47d-16ce2122ef62",
    type: null,
    longitude: -96.826756,
    latitude: 46.718178,
    altitude: null,
    sequence: 4,
    createdByDate: "2021-09-24T18:12:46+00:00",
    createdByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    lastUpdatedByDate: "2021-09-24T21:19:31+00:00",
    lastUpdatedByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    inActiveDate: null,
    isNew: false,
    isUpdate: false,
    isDelete: false,
  },
  {
    id: "224a6d72-d2c8-42a6-9a03-4d259c59a39f",
    companyId: "80b2ebaa-9ddf-48e5-841b-945eb29ad00d",
    farmId: "59eb07d5-c6f0-48c7-b173-d577da690b0f",
    farmVirtualFenceId: "e650eaf0-bdb6-4b7e-b47d-16ce2122ef62",
    type: null,
    longitude: -96.828565,
    latitude: 46.718112,
    altitude: null,
    sequence: 5,
    createdByDate: "2021-09-24T18:12:46+00:00",
    createdByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    lastUpdatedByDate: "2021-09-24T21:21:00+00:00",
    lastUpdatedByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    inActiveDate: null,
    isNew: false,
    isUpdate: false,
    isDelete: false,
  },
  {
    id: "6d149977-c1d7-4b78-9972-296541b9aa34",
    companyId: "80b2ebaa-9ddf-48e5-841b-945eb29ad00d",
    farmId: "59eb07d5-c6f0-48c7-b173-d577da690b0f",
    farmVirtualFenceId: "e650eaf0-bdb6-4b7e-b47d-16ce2122ef62",
    type: null,
    longitude: -96.829535,
    latitude: 46.719773,
    altitude: null,
    sequence: 6,
    createdByDate: "2021-09-24T21:19:31+00:00",
    createdByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    lastUpdatedByDate: "2021-09-24T21:21:00+00:00",
    lastUpdatedByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    inActiveDate: null,
    isNew: false,
    isUpdate: false,
    isDelete: false,
  },
  {
    id: "a809251f-88f6-49ca-8697-5485bbe75d64",
    companyId: "80b2ebaa-9ddf-48e5-841b-945eb29ad00d",
    farmId: "59eb07d5-c6f0-48c7-b173-d577da690b0f",
    farmVirtualFenceId: "e650eaf0-bdb6-4b7e-b47d-16ce2122ef62",
    type: null,
    longitude: -96.830116,
    latitude: 46.722143,
    altitude: null,
    sequence: 7,
    createdByDate: "2021-09-24T21:21:00+00:00",
    createdByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    lastUpdatedByDate: null,
    lastUpdatedByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    inActiveDate: null,
    isNew: false,
    isUpdate: false,
    isDelete: false,
  },
  {
    id: "7d9e7614-a4ed-4e6c-af63-17be86328bb7",
    companyId: "80b2ebaa-9ddf-48e5-841b-945eb29ad00d",
    farmId: "59eb07d5-c6f0-48c7-b173-d577da690b0f",
    farmVirtualFenceId: "e650eaf0-bdb6-4b7e-b47d-16ce2122ef62",
    type: null,
    longitude: -96.830773,
    latitude: 46.723438,
    altitude: null,
    sequence: 8,
    createdByDate: "2021-09-24T21:21:00+00:00",
    createdByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    lastUpdatedByDate: "2021-09-27T13:47:55+00:00",
    lastUpdatedByUserId: "ffacdded-2952-4418-9ab1-53f9b6576aa0",
    inActiveDate: null,
    isNew: false,
    isUpdate: false,
    isDelete: false,
  },
];

const fencePoints = (state = defaultFencePoints, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default fencePoints;
