import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useSelector } from "react-redux";
import { store } from "../index";
import animalAttachmentService from "./animalAttachmentService";
import animalCalvingService from "./animalCalvingService";
import animalCommentService from "./animalCommentService";
import animalLogService from "./animalLogService";
import animalService from "./animalService";
import attachmentService from "./attachmentService";
import commentService from "./commentService";
import companyService from "./companyService";
import fencePointService from "./fencePointService";
import fenceService from "./fenceService";
import groupService from "./groupService";
import logService from "./logService";
import poiPointService from "./poiPointService";
import poiService from "./poiService";
import ranchService from "./ranchService";
import tagService from "./tagService";
import { initialChat } from "../appSettings";
import { v4 as uuid } from "uuid";

let connection = new HubConnectionBuilder()
  .withUrl("https://api.701x.com/chathub", { withCredentials: false })
  .withAutomaticReconnect()
  .build();

const startConnection = () => {
  connection
    .start({ withCredentials: false })
    .then(() => {
      store.dispatch({ type: "JOIN_COMPANY_GROUP" });
      connection.onreconnected(() => {
        console.log("signalR Reconnected, could dispatch to join groups here");
        store.dispatch({ type: "JOIN_COMPANY_GROUP" });
      });
      connection.on("ReceiveMessage", (user, data) => {
        let newMessage = { ...initialChat, ...{ id: uuid(), createdByUserId: user, chatContent: data } };
        store.dispatch({ type: "INSERT_CHAT_REDUCER", payload: newMessage });
        console.log("SignalR Message:", user, data);
      });
      connection.on("BroadcastUsersUpdated");
      connection.on("BroadcastCompaniesUpdated", (company, data) => companyService.updateFromSignalR(data));
      connection.on("BroadcastRanchesUpdated", (company, data) => ranchService.updateFromSignalR(data));
      connection.on("BroadcastGroupsUpdated", (company, data) => groupService.updateFromSignalR(data));
      connection.on("BroadcastFencesUpdated", (company, data) => fenceService.updateFromSignalR(data));
      connection.on("BroadcastFencePointsUpdated", (company, data) => fencePointService.updateFromSignalR(data));
      connection.on("BroadcastPOIsUpdated", (company, data) => poiService.updateFromSignalR(data));
      connection.on("BroadcastPOIPointsUpdated", (company, data) => poiPointService.updateFromSignalR(data));
      connection.on("BroadcastAnimalCalvingsUpdated", (company, data) => animalCalvingService.updateFromSignalR(data));
      connection.on("BroadcastAnimalSiresUpdated");
      connection.on("BroadcastAnimalCommentsUpdated", (company, data) => animalCommentService.updateFromSignalR(data));
      connection.on("BroadcastAnimalAttachmentsUpdated", (company, data) =>
        animalAttachmentService.updateFromSignalR(data)
      );
      connection.on("BroadcastAnimalLogsUpdated", (company, data) => animalLogService.updateFromSignalR(data));
      connection.on("BroadcastAnimalTagsUpdated");
      connection.on("BroadcastTagsUpdated", (company, data) => tagService.updateFromSignalR(data));
      connection.on("BroadcastCommentsUpdated", (company, data) => commentService.updateFromSignalR(data));
      connection.on("BroadcastAttachmentsUpdated", (company, data) => attachmentService.updateFromSignalR(data));
      connection.on("BroadcastLogsUpdated", (company, data) => logService.updateFromSignalR(data));
      connection.on("BroadcastFeedbacksUpdated");
      connection.on("BroadcastAnimalsUpdated", (companyId, data) => animalService.updateFromSignalR(data));
    })
    .catch((error) => console.log(error));
};

const stopConnection = () => {
  if (connection?.connectionState === "Connected") {
    connection.stop();
    connection = new HubConnectionBuilder().withUrl("https://api.701x.com/chathub", { withCredentials: false }).build();
  }
};

const sendMessage = async (group, user, data) => {
  if (connection?.connectionState === "Connected") await connection.send("BroadcastMessage", group, user, data);
};

const joinGroup = (group) => {
  console.log("trying to join group");
  if (group && connection?.connectionState === "Connected") {
    connection.send("AddToGroup", group);
    console.log("connection:", connection);
    console.log("defaults", group);
  } else {
    setTimeout(() => joinGroup(group), 1500);
  }
};

const shouldConnect = () => {
  if (connection?.connectionState === "Connected") {
    return;
  } else {
    stopConnection();
    startConnection();
  }
};

const signalRService = {
  startConnection: startConnection,
  sendMessage: sendMessage,
  joinGroup: joinGroup,
  stopConnection,
  connection,
  shouldConnect,
};

export default signalRService;
