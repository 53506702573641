import { takeEvery, put } from "redux-saga/effects";
import attachmentService from "../../Services/attachmentService";

function* insertAttachmentSaga(action) {
  try {
    const response = yield attachmentService.insert(action.payload);
    yield put({ type: "INSERT_ATTACHMENT_REDUCER", payload: response });
  } catch (err) {
    console.log("error inserting attachment", err);
  }
}
function* updateAttachmentSaga(action) {
  try {
    const response = yield attachmentService.update(action.payload);
    yield put({ type: "UPDATE_ATTACHMENT_REDUCER", payload: response });
  } catch (err) {
    console.log("error updating attachment", err);
  }
}

function* attachmentSaga() {
  yield takeEvery("INSERT_ATTACHMENT", insertAttachmentSaga);
  yield takeEvery("UPDATE_ATTACHMENT", updateAttachmentSaga);
}

export default attachmentSaga;
