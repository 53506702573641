import { combineReducers } from "redux";

import mapState from "./map.reducer";
import oidcAccount from "./oidcAccount.reducer";
import user from "./user.reducer";
import animals from "./animals.reducer";
import companies from "./companies.reducer";
import defaults from "./defaults.reducer";
import fences from "./fence.reducer";
import fencePoints from "./fencePoints.reducer";
import pois from "./poi.reducer";
import groups from "./group.reducer";
import ranches from "./ranch.reducer";
import attachments from "./attachment.reducer";
import animalAttachments from "./animalAttachment.reducer";
import animalCalvings from "./animalCalving.reducer";
import animalLogs from "./animalLog.reducer";
import logs from "./log.reducer";
import tags from "./tag.reducer";
import animalComments from "./animalComment.reducer";
import comments from "./comment.reducer";
import chats from "./chat.reducer";
import loading from "./loading.reducer";
import tagReport from "./tagReport.reducer";

// rootReducer is the primary reducer for our entire project
// It bundles up all of the other reducers so our project can use them.
// This is imported in index.js as rootReducer

// Lets make a bigger object for our store, with the objects from our reducers.
// This is what we get when we use 'state' inside of 'mapStateToProps'
const rootReducer = combineReducers({
  mapState,
  oidcAccount,
  user,
  animals,
  companies,
  defaults,
  fences,
  fencePoints,
  pois,
  groups,
  ranches,
  attachments,
  animalAttachments,
  animalCalvings,
  animalLogs,
  logs,
  tags,
  animalComments,
  comments,
  chats,
  loading,
  tagReport,
});

export default rootReducer;
