import { takeEvery, put } from "redux-saga/effects";
import animalCalvingService from "../../Services/animalCalvingService";

function* massInsertAnimalCalvings(action) {
  try {
    let insertedAnimalCalving = [];
    for (let animalComment of action.payload) {
      const inserted = yield animalCalvingService.insert(animalComment);
      insertedAnimalCalving.push(inserted);
    }
    yield put({ type: "MASS_INSERT_ANIMAL_CALVING_REDUCER", payload: insertedAnimalCalving });
  } catch (err) {
    console.log("error inserting animalCalving", err);
  }
}

function* updateAnimalCalving(action) {
  try {
    console.log("updateAnimalCalving()");
    const updated = yield animalCalvingService.update(action.payload);
    yield put({ type: "UPDATE_ANIMAL_CALVING_REDUCER", payload: updated });
  } catch (err) {
    console.log("error updating animal calving", err);
  }
}

function* insertAnimalCalving(action) {
  try {
    const inserted = yield animalCalvingService.insert(action.payload);
    yield put({ type: "INSERT_ANIMAL_CALVING_REDUCER", payload: inserted });
  } catch (err) {
    console.log("error inserting animalCalving", err);
  }
}
function* deleteAnimalCalving(action) {
  try {
    yield animalCalvingService.deleteFromServer(action.payload);
    yield put({ type: "DELETE_ANIMAL_CALVING_REDUCER", payload: [action.payload.id] });
  } catch (err) {
    console.log("error deleting animalCalving", err);
  }
}

function* animalCalvingSaga() {
  yield takeEvery("MASS_INSERT_ANIMAL_CALVING", massInsertAnimalCalvings);
  yield takeEvery("UPDATE_ANIMAL_CALVING", updateAnimalCalving);
  yield takeEvery("INSERT_ANIMAL_CALVING", insertAnimalCalving);
  yield takeEvery("DELETE_ANIMAL_CALVING", deleteAnimalCalving);
}

export default animalCalvingSaga;
